import React, { useState, FormEvent } from 'react';

import { ID, CharacterInfo } from 'model/types/api.types';
import InputField from 'components/widgets/input_field';
import SubmitButton, { Type } from 'components/widgets/submit_button';
import OriginDropdown from './originDropdown';
import '../styles.css';

type IProps = {
  isNew?: boolean;
  origins: any;
  character: CharacterInfo;
  onSubmit: (id: ID, name: string, thumbnailUrl: string, originId: ID) => void;
}

function CharacterItem({ isNew = false, origins, character, onSubmit }: IProps) {
  const [isEditting, setEdit] = useState<boolean>(isNew);
  const [name, setName] = useState(character.name);
  const [thumbnailUrl, setThumbnailUrl] = useState(character.thumbnailUrl);
  const [originId, setOriginId] = useState(character.originId);

  const onChangeName = (value: string) => {
    setName(value);
  }

  const onChangeThumbnailUrl = (value: string) => {
    setThumbnailUrl(value);
  }

  const onChangeOriginId = (value: ID) => {
    setOriginId(value);
  }

  const onHandleSubmit = (event: FormEvent<HTMLFormElement>) => {
    onSubmit(character.id, name, thumbnailUrl, originId);
    setEdit(false);
    if (isNew) {
      setName('');
      setThumbnailUrl('');
      setOriginId(1);
    }
    event.preventDefault();
  }

  const onToggleEdit = (checked: boolean) => {
    setEdit(checked);
    if (!checked) {
      setName(character.name);
      setThumbnailUrl(character.thumbnailUrl);
      setOriginId(character.originId);
    }
  }

  return (
    <form className="admin_console_console_item_full" onSubmit={onHandleSubmit} >
      <input type="checkbox" checked={isEditting} onChange={(event) => onToggleEdit(event.target.checked)} />
      {!isEditting && <div className="admin_console_origin_item_value">{character.name}</div> }
      {isEditting && <InputField value={name} onChange={onChangeName} iconName="info-circle" placeholder="Name" className="admin_console_origin_item_value" />}
      {!isEditting && <div className="admin_console_origin_item_value_long">{character.thumbnailUrl}</div>}
      {isEditting && <InputField value={thumbnailUrl} onChange={onChangeThumbnailUrl} iconName="info-circle" placeholder="Thumbnail Url" className="admin_console_origin_item_value_long" />}
      {!isNew && !isEditting && <img src={character.thumbnailUrl} alt="icon" className="admin_console_origin_icon_preview" />}
      {!isEditting && <img src={origins[character.originId]?.iconUrl} alt="origin" className="admin_console_origin_icon_preview" /> }
      {isEditting && <OriginDropdown origins={origins} value={originId} onChange={onChangeOriginId} /> }
      {isEditting && <SubmitButton className="admin_console_console_submit_button" type={Type.SAVE} />}
    </form>
  );
}

export default CharacterItem;