import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { selectUi } from 'model/reducers/uiSlice';

import './styles.css';

let resizeTimeout: any;

type IProps = {
  mainPanel: JSX.Element,
  watchPanel: JSX.Element,
  sidePanel: JSX.Element,
}

function Panel({ mainPanel, watchPanel, sidePanel }: IProps) {
  const ref = useRef<HTMLDivElement>(null);
  const { watchSelected, sidebarExpanded } = useSelector(selectUi);

  useEffect( () => {
    const handleResize = () => {
      clearTimeout(resizeTimeout);
      ref.current?.classList.add('no_transition');
      resizeTimeout = setTimeout( () => {
        ref.current?.classList.remove('no_transition');
      }, 100);
    }
    return window.addEventListener('resize', handleResize);
  });

  return (
    <div className="media_panel_container">
      <div className={(watchSelected ? "media_panel_watch_panel_open" : "")} />
      <div className="media_panel_watch_panel">
        {watchPanel}
      </div>
      <div ref={ref}
          className={
            "media_panel_main_panel " +
            (sidebarExpanded ? "media_panel_main_panel_side_open" : "media_panel_main_panel_closed")
      }>
        {mainPanel}
      </div>
      <div ref={ref}
          className={
            "media_panel_side_panel " + 
            (sidebarExpanded ? "media_panel_main_panel_offset " : "") +
            (watchSelected ? "media_panel_watch_panel_offset ": "media_panel_side_panel_none")
      }>
        {sidePanel}
      </div>
    </div>
  );
}

export default Panel