import React, { MouseEvent } from 'react';
import { OptionItem } from './index';
import './styles.css';

type IProps = {
  option: OptionItem,
  onSelect: () => void;
}

function OptionMenuItem({ option, onSelect }: IProps) {

  const onHandleSelect = (event: MouseEvent<HTMLDivElement>) => {
    option.action(event);
    onSelect();
  }

  return (
    <div className="option_menu_item_container" title={option.title} onClick={onHandleSelect}>
      {option.icon}
    </div>
  );
}


export default OptionMenuItem;