import React, { useState, FormEvent } from 'react';
import { CharacterOrigin } from 'model/types/api.types';
import InputField from 'components/widgets/input_field';
import SubmitButton, { Type } from 'components/widgets/submit_button';
import '../styles.css';

type IProps = {
  isNew?: boolean;
  origin: CharacterOrigin;
  onSubmit: (id: number, name: string, iconUrl: string) => void;
}

function OriginItem({ isNew = false, origin, onSubmit }: IProps) {
  const [isEditting, setEdit] = useState<boolean>(isNew);
  const [name, setName] = useState(origin.name);
  const [iconUrl, setIconUrl] = useState(origin.iconUrl);

  const onChangeName = (value: string) => {
    setName(value);
  }

  const onChangeIconUrl = (value: string) => {
    setIconUrl(value);
  }

  const onHandleSubmit = (event: FormEvent<HTMLFormElement>) => {
    onSubmit(origin.id, name, iconUrl);
    setEdit(false);
    if (isNew) {
      setName('');
      setIconUrl('');
    }
    event.preventDefault();
  }

  const onToggleEdit = (checked: boolean) => {
    setEdit(checked);
    if (!checked) {
      setName(origin.name);
      setIconUrl(origin.iconUrl);
    }
  }

  return (
    <form className="admin_console_console_item_full" onSubmit={onHandleSubmit}>
      <input type="checkbox" checked={isEditting} onChange={(event) => onToggleEdit(event.target.checked)} />
      {!isEditting && <div className="admin_console_origin_item_value">{origin.name}</div>}
      {isEditting && <InputField value={name} onChange={onChangeName} iconName="info-circle" placeholder="Name" className="admin_console_origin_item_value" />}

      {!isEditting && <div className="admin_console_origin_item_value_long">{origin.iconUrl}</div>}
      {isEditting && <InputField value={iconUrl} onChange={onChangeIconUrl} iconName="info-circle" placeholder="Icon Url" className="admin_console_origin_item_value_long" />}
      {!isNew && !isEditting && <img src={origin?.iconUrl} alt="icon" className="admin_console_origin_icon_preview" />}
      {isEditting && <SubmitButton className="admin_console_console_submit_button" type={Type.SAVE} />}
    </form>
  );
}

export default OriginItem;