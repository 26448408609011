import React, { useState, useEffect, FormEvent } from 'react';
import { useDispatch } from 'model/store';
import { Media } from 'model/types/api.types';
import { editMediaMusic } from 'model/reducers/mediaSlice';

import useToast from 'components/widgets/toast/useToast';
import Icon, { IconColor } from 'components/widgets/icon';
import InputField from 'components/widgets/input_field';
import SubmitButton, { Type } from 'components/widgets/submit_button';
import './styles.css';

type IProps = {
  media: Media,
  canEdit: boolean,
}

function SongInfo({ media, canEdit }: IProps) {
  const dispatch = useDispatch();
  const [isEditting, setEditting] = useState(false);
  const [songName, setSongName] = useState(media?.songName ?? '');
  const [artistName, setArtistName] = useState(media?.artistName ?? '');
  const { addErrorToast } = useToast();

  useEffect( () => {
    setSongName(media?.songName ?? '');
    setArtistName(media?.artistName ?? '');
  }, [media]);

  const onEdit = () => {
    if (canEdit) setEditting(true);
  }

  const onCancel = () => {
    setEditting(false);
    setSongName(media?.songName ?? '');
    setArtistName(media?.artistName ?? '');
  }

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(editMediaMusic({
      id: 0,
      mediaId: media.id,
      title: songName.trim(),
      artist: artistName.trim(),
    })).then( () => setEditting(false))
    .catch(addErrorToast);
    return false;
  }

  return (
    <div className="media_item_info_music">
      {!isEditting && <div className="media_item_info_song">
        <div onClick={onEdit}>
          <Icon name="music-note-beamed" color={IconColor.GREYER} className={"media_item_info_icon " + (canEdit ? "media_item_info_editable" : "")} alt="song" title="Song" />
          <span>{media.songName}</span>
        </div>
        <div className="media_item_info_music_artist">
          <span>{media.artistName}</span>
        </div>
      </div>}
      {isEditting && <form className="media_item_info_song_form" onSubmit={onSubmit}>
        <div className="media_item_info_song_fields">
          <InputField iconName="music-note-beamed" placeholder="Song" value={songName} onChange={(value) => setSongName(value)} autoFocus />
          <InputField iconName="person-square" placeholder="Artist" value={artistName} onChange={(value) => setArtistName(value)} />
          <div className="media_item_info_save_form_buttons">
            <SubmitButton className="media_item_info_song_save" type={Type.SAVE} />
            <SubmitButton className="media_item_info_song_save" type={Type.CANCEL} onClick={onCancel} />
          </div>
        </div>
      </form>}
    </div>
  );
}

export default SongInfo;