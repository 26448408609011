import React from 'react';

import Menubar from 'components/menubar';
import MediaSearch from 'components/media/search';
import User from 'components/user';
import Info from 'json/info.json';
import './header.css';

function Header() {

  const onMenuOpen = (isOpen: boolean) => {}

  return (
    <>
      <div className="header_container">
        <div className="header_content">
          <Menubar onOpen={onMenuOpen}>
            {[
            <img key="header_menu_img" src="/assets/icons/info-circle.svg" className="header_content_logo" />,
            <div key="header_menu_text" className="header_content_text">
              <span>{Info.infoText}</span>
            </div>]}
          </Menubar>
          <MediaSearch />
          <User />
        </div>
      </div>
    </>
  );
}

export default Header;