export enum ToastType {
  INFO = "INFO",
  ERROR = "ERROR",
}

export type Toast = {
  id?: number;
  type: ToastType;
  message: string;
}

export type ToastState = {
  idCounter: number;
  toasts: Toast[];
}