export enum HttpMethod {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
}

export type ResponseError = {
  statusCode: number;
  message: string;
}

export type StrID = string | ID | null;

export type ID = number;

export type Token = string;

export type Cursor = number;

export type SortBy = {
  field: string;
  ascending: boolean;
}

export type PageInfo = {
  hasNext: boolean;
  total: number;
  page: Cursor;
  sortedBy?: SortBy;
}

export type PageItems<Item> = {
  items: Item[];
  pageInfo: PageInfo;
}

export type UserInfo = {
  token?: Token;
  email: string;
  avatarUrl: string;
  permissions: string;
}

export type CharacterOrigin = {
  id: ID;
  name: string;
  iconUrl: string;
}

export type CharacterInfo = {
  id: ID;
  mediaId?: ID;
  name: string;
  thumbnailUrl: string;
  originId: ID;
}

export type Media = {
  id: ID;
  mediaSourceId: string;
  name: string;
  songName: string;
  artistName: string;
  characters: CharacterInfo[];
  tags: string[];
}

export type SongInfo = {
  id: ID,
  mediaId?: ID,
  title: string;
  artist: string;
}

export type Playlist = {
  id: StrID;
  name: string;
  media: Media[];
}

export type Tag = string;
export type Tags = Tag[];

/* Requests */

export type MediaBaseRequest = {
  mediaId: ID;
}

export type PageRequest = {
  page: Cursor;
}

export type MediaRequest = {
  mediaSourceId: string;
}

export type SongRequest = {
  title: string,
  artist: string,
}

export type MediaIdsRequest = {
  mediaIds: ID[];
}

export type NameRequest = {
  name: string;
}

export type CharacterRequest = MediaBaseRequest & {
  characterIds: ID[];
}

export type TagRequest = MediaBaseRequest & {
  tags: string[];
}

export type SearchFilter = {
  id: ID;
  type?: string;
}

export type MediaSearchQuery = {
  name: string;
  characters: SearchFilter[];
  songs: SearchFilter[];
  tags: string[];
}