import React, { useState, useEffect } from 'react';
import YouTube, { YouTubeEvent } from 'react-youtube';
import { Media } from 'model/types/api.types';
import './styles.css';

type IProps = {
  media: Media,
  width: number,
  onEnd: (event: YouTubeEvent) => void,
}

function WatchContent({ media, width, onEnd }: IProps) {
  const [player, setPlayer] = useState<any>();

  useEffect( () => {
    if (player && media) {
      player.loadVideoById(media.mediaSourceId);
    }
  }, [player, media]);

  const onReady = (event: YouTubeEvent) => {
    setPlayer(event.target);
  }

  return (
    <div className="media_watch_content">
      <YouTube
        onReady={onReady}
        onEnd={onEnd}
        opts={{
          height: '360',
          width,
          playerVars: {
            autoplay: 1
          }
        }} />
    </div>
  );
}

export default WatchContent;