import { HttpMethod, Cursor, Token, PageItems, ResponseError } from 'model/types/api.types';
import Info from 'json/info.json';

type ResponseType<TResponse> = ResponseError & TResponse;

const server_url = Info.server;

class HttpResponseError extends Error {
  statusCode: number;
  constructor({ statusCode, message }: ResponseError) {
    super(message);
    this.statusCode = statusCode;
    this.message = message;
  }
}

const onResponseJson = (response: any) => {
  return response.json();
}

function request<TResponse>(url: string, config: RequestInit = {}): Promise<TResponse> {
  return fetch(server_url + url, config).then(onResponseJson)
    .then((data: ResponseType<TResponse>) => {
      if (data?.statusCode > 200) throw new HttpResponseError(data);
      return data
    });
}

const toSearchParams = (body: any) => {
  return new URLSearchParams(body);
}

const postRequest = async<ResponseType, RequestType = {}>(url: string, body: RequestType): Promise<ResponseType> => {
  return request<ResponseType> (url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });
}

const authRequest = async<ResponseType, RequestType= {}>(method: HttpMethod, url: string, authToken: Token, body?: RequestType): Promise<ResponseType> => {
  return request<ResponseType>(url, {
    method,
    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
    body: JSON.stringify(body),
  });
}

export const postAuthRequest = async<ResponseType, RequestType = {}>(url: string, authToken: Token, body?: RequestType): Promise<ResponseType> => {
  return authRequest<ResponseType, RequestType>(HttpMethod.POST, url, authToken, body);
}

export const deleteAuthRequest = async<ResponseType, RequestType = {}>(url: string, authToken: Token, body: RequestType): Promise<ResponseType> => {
  return authRequest<ResponseType, RequestType>(HttpMethod.DELETE, url, authToken, body);
}

export const getAuthRequest = async<ResponseType>(url: string, authToken: Token): Promise<ResponseType> => {
  return request<ResponseType>(url, {
    method: HttpMethod.GET,
    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` },
  });
}

export const postRequestPagination = async<ResponseType, RequestType = {}>(url: string, page: Cursor, count: number, body: RequestType): Promise<PageItems<ResponseType>> => {
  return postRequest<PageItems<ResponseType>, RequestType>(url, {
    page,
    count,
    ...body,
  });
}

export const getRequest = async<ResponseType, RequestParam={}>(url: string, params: RequestParam): Promise<ResponseType> => {
  const paramQuery = toSearchParams(params);
  return request<ResponseType>(url +'?' + paramQuery.toString(), {
    mode: 'cors'
  });
}

export const getRequestPagination = async<ResponseType, RequestParam = {}, >(url: string, page: Cursor, count: number, params?: RequestParam): Promise<PageItems<ResponseType>> => {
  return getRequest<PageItems<ResponseType>>(url, {
    page,
    count,
    ...params
  });
}

export const getAuthRequestPagination =  async<ResponseType, RequestParam = {}, >(url: string, authToken: Token, page: Cursor, count: number, params?: RequestParam): Promise<PageItems<ResponseType>> => {
 const paramQuery = toSearchParams({ page, count, ...params });
  return getAuthRequest<PageItems<ResponseType>>(url + '?' + paramQuery.toString(), authToken);
}