import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { get_searchSong } from 'model/apis/apis';
import { ID, PageItems, PageRequest, SongInfo } from 'model/types/api.types';
import { SongState } from 'model/types/song.types';
import { RootState } from 'model/store';

type NameParams = PageRequest & {
  name: string;
}

const initialState: SongState = {
  isLoading: false,
}

const loadPending = (state: SongState) => {
  state.isLoading = true;
}

const loadFinished = (state: SongState) => {
  state.isLoading = false;
}

const loadRejected = (state: SongState, payload: any) => {
  state.isLoading = false;
  throw new Error(payload.error.message);
}

export const searchSong = createAsyncThunk('/song/search', async({ page, name }: NameParams) => {
  return await get_searchSong(page, name, name);
});

export const songSlice = createSlice({
  name: 'songState',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(searchSong.pending, loadPending)
      .addCase(searchSong.rejected, loadRejected)
      .addCase(searchSong.fulfilled, loadFinished)
  }
});

export default songSlice.reducer;