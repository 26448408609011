import { useEffect } from 'react';
import { useDispatch } from 'model/store';
import { listCharacterOrigins } from 'model/reducers/characterSlice';
import useToast from 'components/widgets/toast/useToast';

function OriginLoader() {
  const { addErrorToast } = useToast();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(listCharacterOrigins({ page: 0})).catch(addErrorToast);
  }, [dispatch, addErrorToast]);
  return <></>;
}

export default OriginLoader;