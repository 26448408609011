import React, { useEffect, useRef } from 'react';
import { useSelector } from 'model/store';
import { selectUser } from 'model/reducers/userSlice';

import Icon, { IconColor } from 'components/widgets/icon';
import UserSettings from './userSettings';

type IProps = {
  isShown: boolean,
  toggleShown: (isShown: boolean) => void,
}

function UserPanel({ isShown, toggleShown }: IProps) {
  const ref = useRef<HTMLDivElement>(null);
  const { userInfo, permissions } = useSelector(selectUser);

  useEffect(() => {
    const onOutsideClicked = (event: Event) => {
      if (ref.current && !ref.current.contains(event.target as HTMLElement)) {
        toggleShown(false);
      }
    };
    document.addEventListener("mousedown", onOutsideClicked);
    return () => document.removeEventListener("mousedown", onOutsideClicked);
  }, [toggleShown]);

  const onLogout = () => {
    localStorage.removeItem('user_cred');
    window.location.reload();
  }

  if (!isShown) return <></>;

  let userIconColor = IconColor.GREY;
  if (permissions.canEdit) userIconColor = IconColor.BLUE;

  return (
    <article ref={ref} className="user_profile_info_container">
      <section className="user_profile_settings_container user_profile_settings_user_container">
        <Icon name="person-badge-fill" className="user_profile_settings_container_icon" color={userIconColor} alt="badge" />
        <span>{userInfo.email}</span>
      </section>
      <section className="user_profile_settings_container user_profile_settings_info_container">
        <UserSettings />
      </section>
      <section className="user_profile_settings_container user_profile_settings_logout_container">
        <div className="user_profile_settings_logout_button" onClick={onLogout}>
          <Icon name="box-arrow-right" className="user_profile_settings_container_icon" color={IconColor.GREY} alt="logout" />
          <span>Logout</span>
        </div>
      </section>
    </article>
  );
}

export default UserPanel;