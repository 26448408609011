import React, { useEffect } from 'react';
import { ToastType } from 'model/types/toast.types';

import { Toast } from 'model/types/toast.types';
import useToast from './useToast';
import Icon, { IconColor } from 'components/widgets/icon';

import './styles.css';

type IProps = {
  toast: Toast,
  onClose: (id?: number) => void,
}

function ToastItem({ toast, onClose }: IProps) {
  const { popToast } = useToast();

  useEffect( () => {
    popToast(toast.id);
  }, [popToast, toast]);

  const onCloseToast = () => {
    onClose(toast.id);
  }

  let icon = null;
  switch(toast.type) {
    case ToastType.ERROR: icon = <Icon name="exclamation-triangle-fill" color={IconColor.RED} alt="error" className="toast_icon" />
      break;
    case ToastType.INFO: icon = <Icon name="info-square-fill" color={IconColor.GREEN} alt="info" className="toast_icon" />
      break;
    default: break;
  }

  return (
    <div className="toast_container">
      <div className="toast_header">
        <img className="toast_close_icon" src="/assets/icons/x.svg" alt="close" onClick={onCloseToast} />
      </div>
      <div className="toast_content">
        {icon}
        <span>{toast.message}</span>
      </div>
    </div>
  );
}

export default ToastItem;