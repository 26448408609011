import React, { useState, useEffect, FormEvent } from 'react';
import { useDispatch } from 'model/store';
import { ID, Media } from 'model/types/api.types';
import { editMediaCharacter } from 'model/reducers/mediaSlice';

import useToast from 'components/widgets/toast/useToast';
import CharacterSearch from 'components/character/search';
import Icon, { IconColor } from 'components/widgets/icon';
import SaveCancelButtons from 'components/widgets/save_cancel_buttons';
import './styles.css';

type IProps = {
  media: Media,
  canEdit: boolean,
}

function CharactersInfo({ media, canEdit }: IProps) {
  const dispatch = useDispatch();
  const [isEditting, setEditting] = useState(false);
  const [characterIds, setCharacterIds] = useState((media?.characters ?? []).map(character => character.id));
  const { addErrorToast } = useToast();

  useEffect( () => {
    setCharacterIds((media?.characters ?? []).map(character => character.id));
  }, [media]);

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(editMediaCharacter({
        mediaId: media.id,
        characterIds
      }))
    .then( () => setEditting(false))
    .catch(addErrorToast);
    return false;
  }

  const onEdit = async () => {
    if (canEdit) await setEditting(true);
  }

  const onCancel = () => {
    setEditting(false);
  }

  const onSelect = (characterIds: ID[]) => {
    setCharacterIds(characterIds);
  }

  return (
    <div className="media_item_info_character">
      {!isEditting && <>
        <div className={"character_panel_container " + (canEdit ? "media_item_info_editable" : "")} onClick={onEdit}>
          {(media?.characters || []).map( (character, index) => (
              <div key={index} className="character_panel_item">
                <img src={character.thumbnailUrl} alt={`${character.name}_icon`} onError={
                  ({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = '/assets/icons/person-fill.svg';
                  } }
                  className="character_panel_icon" />
                <div className="character_panel_name">{character.name}</div>
              </div>
          ))}
          { canEdit && media?.characters.length === 0 && <div className="character_panel_icon_add">
            <Icon name="plus-circle-fill" color={IconColor.GREEN} className="character_panel_icon_add_icon" />
          </div> }
        </div>
      </>}
      {isEditting && <form className="media_item_edit_character" onSubmit={onSubmit}>
        <CharacterSearch ids={characterIds} onSelect={onSelect} onCancel={onCancel} />
        <SaveCancelButtons onCancel={onCancel} />
      </form>}
    </div>
  );
}

export default CharactersInfo;