import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { 
    post_createMedia,
    post_deleteMedia,
    post_searchMedia,
    post_editSong,
    post_addCharacter,
    post_tag,
    get_listMedia,
} from '../apis/apis';

import { MAX_MEDIA_SIZE } from 'model/constants/sizes';
import { getAuthToken } from 'model/utils/useUtils';
import { PageItems, PageRequest, MediaRequest, MediaSearchQuery, CharacterRequest, TagRequest, Media, SongInfo } from 'model/types/api.types';
import { MediaState, MediaItemList, MediaItem } from 'model/types/media.types';
import { RootState } from 'model/store';

const emptySearchQuery: MediaSearchQuery = {
  name: '',
  characters: [],
  songs: [],
  tags: [],
}

const initialState: MediaState = {
  isLoading: false,
  isSearch: false,
  addedMedia: undefined,
  list: { items: [], pageInfo: { page: 0, hasNext: false, total: 0 }, searchQuery: emptySearchQuery },
  searchQuery: emptySearchQuery,
}

const getState = (thunkAPI: any): MediaState => {
  return (thunkAPI.getState() as RootState).mediaState;
}

export const changeSearchQuery = createAsyncThunk('/media/searchQuery', async (searchQuery: MediaSearchQuery) => {
  return searchQuery;
});

export const createMedia = createAsyncThunk('/media/create', async ({ mediaSourceId }: MediaRequest, thunkAPI) => {
  const media = await post_createMedia(getAuthToken(thunkAPI), { mediaSourceId });
  return { mediaIndex: 0, media };
});

export const deleteMedia = createAsyncThunk('/media/delete', async ({ mediaSourceId }: MediaRequest, thunkAPI) => {
  const media = await post_deleteMedia(getAuthToken(thunkAPI), { mediaSourceId });
  const { list } = getState(thunkAPI);
  const mediaIndex = list.items.findIndex( item => item.id === media.id );
  return { mediaIndex, media };
});

export const searchMedia = createAsyncThunk('/media/search', async ( { name, characters, songs }: MediaSearchQuery) => {
  const searchQuery: MediaSearchQuery = { 
    name,
    characters,
    songs,
    tags: [],
  };
  const data = await post_searchMedia(0, searchQuery);
  return {
    items: data.items,
    pageInfo: data.pageInfo,
    searchQuery,
  }
});

export const searchMediaMore = createAsyncThunk('/media/search/more', async ({ page }: PageRequest, thunkAPI) => {
  const { list } = getState(thunkAPI);
  const data = await post_searchMedia(page, list.searchQuery);
  return {
    items: data.items,
    pageInfo: data.pageInfo,
    searchQuery: list.searchQuery,
  } as MediaItemList
});

export const listMedia = createAsyncThunk('/media/list', async () => {
  const media: PageItems<Media> = await get_listMedia(0);
  return { items: media.items, pageInfo: media.pageInfo, searchQuery: emptySearchQuery };
});

export const listMediaMore = createAsyncThunk('/media/list/more', async ({ page }: PageRequest) => {
  return await get_listMedia(page);
});

export const editMediaMusic = createAsyncThunk('/media/song/edit', async ({ id, mediaId, title, artist }: SongInfo, thunkAPI) => {
  const { list } = getState(thunkAPI);
  const song = await post_editSong(getAuthToken(thunkAPI), { id, mediaId, title, artist });
  const mediaIndex = list.items.findIndex( item => item.id === mediaId );
  return {
    mediaIndex,
    media: {
      ...list.items[mediaIndex],
      songName: song.title,
      artistName: song.artist,
    },
  };
});

export const editMediaCharacter = createAsyncThunk('/media/character/edit', async ({ mediaId, characterIds }: CharacterRequest, thunkAPI) => {
  const characters = await post_addCharacter(getAuthToken(thunkAPI), { mediaId, characterIds });
  const { list } = getState(thunkAPI);
  const mediaIndex = list.items.findIndex( item => item.id === mediaId );
  return {
    mediaIndex,
    media: {
      ...list.items[mediaIndex],
      characters,
    },
  }
});

export const editMediaTags = createAsyncThunk('/media/tags/edit', async ({ mediaId, tags }: TagRequest, thunkAPI) => {
  const { list } = getState(thunkAPI);
  const mediaIndex = list.items.findIndex( item => item.id === mediaId );
  return {
    mediaIndex,
    media: {
      ...list.items[mediaIndex],
      tags: await post_tag(getAuthToken(thunkAPI), { mediaId, tags }),
    }
  }
});

const loadPending = (state: MediaState) => {
  state.isLoading = true;
}

const loadFinished = (state: MediaState) => {
  state.isLoading = false;
}

const loadRejected = (state: MediaState, payload: any) => {
  state.isLoading = false;
  throw new Error(payload.error.message);
}

export const mediaSlice = createSlice({
  name: 'mediaState',
  initialState,
  reducers: {
    clearAddedMedia(state) {
      state.addedMedia = undefined;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(createMedia.pending, loadPending).addCase(createMedia.rejected, loadRejected)
      .addCase(deleteMedia.pending, loadPending).addCase(deleteMedia.rejected, loadRejected)
      .addCase(changeSearchQuery.pending, () => {}).addCase(changeSearchQuery.rejected, loadRejected)
      .addCase(listMedia.pending, loadPending).addCase(listMedia.rejected, loadRejected)
      .addCase(listMediaMore.pending, loadPending).addCase(listMediaMore.rejected, loadRejected)
      .addCase(searchMedia.pending, loadPending).addCase(searchMedia.rejected, loadRejected)
      .addCase(searchMediaMore.pending, loadPending).addCase(searchMediaMore.rejected, loadRejected)
      .addCase(editMediaMusic.pending, loadPending).addCase(editMediaMusic.rejected, loadRejected)
      .addCase(editMediaCharacter.pending, loadPending).addCase(editMediaCharacter.rejected, loadRejected)
      .addCase(editMediaTags.pending, loadPending).addCase(editMediaTags.rejected, loadRejected)
      .addCase(createMedia.fulfilled, (state: MediaState, { payload }: PayloadAction<MediaItem>) => {
        state.list.items.splice(payload.mediaIndex, 0, payload.media);
        state.addedMedia = payload.media;
      })
      .addCase(deleteMedia.fulfilled, (state: MediaState, { payload }: PayloadAction<MediaItem>) => {
        state.list.items.splice(payload.mediaIndex, 1);
      })
      .addCase(changeSearchQuery.fulfilled, (state: MediaState, { payload }: PayloadAction<MediaSearchQuery>) => {
        state.searchQuery = payload;
      })
      .addCase(listMedia.fulfilled, (state: MediaState, { payload }: PayloadAction<MediaItemList>) => {
        state.isSearch = false;
        state.list = { ...payload };
        loadFinished(state);
      })
      .addCase(listMediaMore.fulfilled, (state: MediaState, { payload }) => {
          if (state.list?.items.length > MAX_MEDIA_SIZE) {
            state.list.items = [...payload.items];
          } else {
            state.list?.items?.push(...payload.items);
          }
          state.list.pageInfo = payload.pageInfo;
          loadFinished(state);
      })
      .addCase(searchMedia.fulfilled, (state: MediaState, { payload }: PayloadAction<MediaItemList>) => {
        state.isSearch = true;
        state.list = { ...payload };
        loadFinished(state);
      })
      .addCase(searchMediaMore.fulfilled, (state: MediaState, { payload }: PayloadAction<MediaItemList>) => {
        if (state.list?.items.length > MAX_MEDIA_SIZE) {
          state.list.items = [...payload.items];
        } else {
          state.list?.items?.push(...payload.items);
        }
        state.list.pageInfo = payload.pageInfo;
        loadFinished(state);
      })
      .addCase(editMediaMusic.fulfilled, (state: MediaState, { payload }: PayloadAction<MediaItem>) => {
        state.list.items[payload.mediaIndex] = payload.media;
        if (state.addedMedia?.id === payload.media.id) state.addedMedia = payload.media;
      })
      .addCase(editMediaCharacter.fulfilled, (state: MediaState, { payload }: PayloadAction<MediaItem>) => {
        state.list.items[payload.mediaIndex] = payload.media;
        if (state.addedMedia?.id === payload.media.id) state.addedMedia = payload.media;
      })
      .addCase(editMediaTags.fulfilled, (state: MediaState, { payload }: PayloadAction<MediaItem>) => {
        state.list.items[payload.mediaIndex] = payload.media;
        if (state.addedMedia?.id === payload.media.id) state.addedMedia = payload.media;
      });
  },
});

export const selectMedia = (state: RootState): MediaState => {
  return state.mediaState;
}

export const { actions } = mediaSlice;
export default mediaSlice.reducer;