import React, { useState, FormEvent } from 'react';
import { useDispatch } from 'model/store';
import { Media } from 'model/types/api.types';
import { editMediaTags } from 'model/reducers/mediaSlice';

import useToast from 'components/widgets/toast/useToast';
import Icon, { IconColor } from 'components/widgets/icon';
import InputField from 'components/widgets/input_field';
import SubmitButton, { Type } from 'components/widgets/submit_button';
import './styles.css';

const parseHashTags = (tagsStr: string) => {
  return (tagsStr ?? '')
    .split(/ *(#[A-Za-z]*)/)
    .map( hashTag => hashTag.replace('#', ''))
    .filter( hashTag => hashTag.length > 0);
}

const joinHashTags = (tags: string[]) => {
  return '#' + (tags ?? []).join(' #').trim();
}

type IProps = {
  media: Media,
  canEdit: boolean,
}

function EditTag({ media, canEdit }: IProps) {
  const dispatch = useDispatch();
  const [isEditting, setEditting] = useState(false);
  const [hashTags, setHashTags] = useState(joinHashTags(media?.tags));
  const { addErrorToast } = useToast();

  const onEdit = () => {
    if (canEdit) setEditting(true);
  }

  const onCancel = () => {
    setEditting(false);
    setHashTags(joinHashTags(media?.tags));
  }

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(editMediaTags({
        mediaId: media.id,
        tags: parseHashTags(hashTags)
      }))
      .catch(addErrorToast);
    setEditting(false);
    return false;
  }

  return (
    <div className="media_item_info_tags">
      {!isEditting && <>
          <Icon name="tags" color={IconColor.GREYER} className={"media_item_info_icon " + (canEdit ? "media_item_info_editable" : "")} alt="tags" title="Tags" onClick={onEdit} />
          {(media?.tags ?? []).map( (tag, index) => <span key={tag+'_'+index}>#{tag}</span>)}
      </>}
      {isEditting && <form className="media_item_tag_form" onSubmit={onSubmit}>
        <InputField className="media_item_tag_input" iconName="tags" placeholder="Tags" value={hashTags} onChange={(value) => setHashTags(value)} autoFocus />
        <SubmitButton className="media_item_tag_button" type={Type.SAVE} />
        <SubmitButton className="media_item_tag_button" type={Type.CANCEL} onClick={onCancel} />
      </form>}
    </div>
  );
}

export default EditTag;