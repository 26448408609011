import React, { useEffect } from 'react';

type IProps = {
  isLoading: boolean,
  children: JSX.Element[],
  loadMore: () => void,
  hasMore: boolean,
  height: number,
}

const withinBound = (x: number, y: number, delta: number) => {
    return x >= y - delta && x <= y + delta;
}

function ScrollLoader({isLoading, children, loadMore, hasMore, height}: IProps) {

  const loadUntilScrollable = () => {
    const scrollHeight = (document.documentElement
      && document.documentElement.scrollHeight)
      || document.body.scrollHeight;
    if (withinBound(window.innerHeight, scrollHeight, 15) && !isLoading && hasMore) {

    }
  }

  const onScrollLoad = () => {
    const scrollTop = (document.documentElement
      && document.documentElement.scrollTop)
      || document.body.scrollTop;
    const scrollHeight = (document.documentElement
      && document.documentElement.scrollHeight)
      || document.body.scrollHeight;
    if (scrollTop + window.innerHeight + height >= scrollHeight && !isLoading && hasMore) {
      loadMore();
    }
  }

  useEffect(() => {
    loadUntilScrollable();
    window.addEventListener('scroll', onScrollLoad);
    return () => window.removeEventListener('scroll', onScrollLoad);
  });

  return (
    <>
      {children}
    </>
  )
}

export default ScrollLoader;