import React, { useState, useEffect, FormEvent } from 'react';

import { Token, UserInfo } from 'model/types/api.types';
import { get_listUser, post_grantPermission } from 'model/apis/apis';

import InputField from 'components/widgets/input_field';
import UserItem from './UserItem';
import '../styles.css';

type IProps = {
  authToken: Token,
}

function UserConsole({ authToken }: IProps) {
  const [searchId, setSearchId] = useState('');
  const [users, setUsers] = useState<UserInfo[]>([]);

  useEffect( () => {
    get_listUser(authToken, '', 0).then( response => setUsers(response?.items ?? []));
  }, [setUsers, authToken]);

  const onGrantPermission = (email: string, permissions: string) => {
    post_grantPermission(authToken, { email, avatarUrl: '', permissions } );
    get_listUser(authToken, searchId, 0).then( response => setUsers(response?.items ?? []));
  }

  const onSearchId = (value: string) => {
    setSearchId(value);
  }

  const onSubmitSearch = (event: FormEvent<HTMLFormElement>) => {
    get_listUser(authToken, searchId, 0).then( response => setUsers(response?.items ?? []));
    event.preventDefault();
    return false;
  }

  return (
    <div className="admin_console_console">
      <form onSubmit={onSubmitSearch}>
        <InputField value={searchId} onChange={onSearchId} iconName="person-vcard-fill" placeholder="Search" />
      </form>
      <div className="admin_console_console_list">
        <div className="admin_console_user_console_item_header admin_console_console_item">
          <div className="admin_console_user_console_item_col_first">Email</div>
          <div className="admin_console_user_console_item_col">BASIC</div>
          <div className="admin_console_user_console_item_col">WRITE_TAG</div>
          <div className="admin_console_user_console_item_col">BASIC_WRITE</div>
          <div className="admin_console_user_console_item_col">BASIC_DELETE</div>
          <div className="admin_console_user_console_item_col">ADVANCE_WRITE</div>
          <div className="admin_console_user_console_item_col">PREMIUM</div>
          <div className="admin_console_user_console_item_save"></div>
        </div>
        { users.map( (user, index) => <UserItem key={index} onSubmit={onGrantPermission} user={user} /> ) }
        <UserItem onSubmit={onGrantPermission} user={{ email: '', avatarUrl: '', permissions: '1000000' }} isNew={true} />
      </div>
    </div>
  );
}

export default UserConsole;