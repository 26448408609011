import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Toast, ToastState } from 'model/types/toast.types';
import { RootState } from 'model/store';

const initialState: ToastState = {
  idCounter: 0,
  toasts: [],
};

export const uiSlice = createSlice({
  name: 'toastState',
  initialState,
  reducers: {
    showToast(state, { payload }: PayloadAction<Toast>) {
      state.toasts.push({...payload, id: ++state.idCounter });
    },
    popToast(state) {
      state.toasts.pop();
    },
    closeToast(state, { payload }) {
      state.toasts = state.toasts.filter( (item) => item.id !== payload );
    },
    clearToast(state) {
      state.toasts = [];
    }
  },
});

export const selectToast = (state: RootState): ToastState => {
    return state.toastState;
}

export const { actions } = uiSlice;

export default uiSlice.reducer;