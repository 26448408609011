import React, { useState, useEffect, useMemo, useCallback, useRef, FormEvent, KeyboardEvent } from 'react';
import { useDispatch, useSelector } from 'model/store';
import { ID, CharacterInfo, SongInfo, MediaSearchQuery } from 'model/types/api.types';
import { selectMedia, changeSearchQuery, searchMedia } from 'model/reducers/mediaSlice';
import { selectCharacter, searchCharacter } from 'model/reducers/characterSlice';
import { searchSong } from 'model/reducers/songSlice';
import { DEBOUCE_TIMER } from 'model/constants/sizes';

import useToast from 'components/widgets/toast/useToast';
import TokenInput, { Token } from 'components/widgets/token_input';
import Icon, { IconColor} from 'components/widgets/icon';
import SearchDropdown, { ItemType, SearchItem } from './searchDropdown';
import './styles.css';

type SelectedId = {
  type: ItemType,
  id: ID,
}

const MAX_ITEM_SHOW = 10;

function MediaSearch() {
  const ref = useRef<HTMLFormElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();

  const [isFocused, setFocused] = useState(false);
  const [showSelector, setShowSelector] = useState(false);
  const [hoverIndex, setHoverIndex] = useState(0);

  const [query, setQuery] = useState('');
  const [songs, setSongs] = useState<SongInfo[]>([]);

  const { searchQuery } = useSelector(selectMedia);
  const { origins, characters, searchedNames } = useSelector(selectCharacter);
  const { addErrorToast } = useToast();

  useEffect(() => {
    const onOutsideClicked = (event: Event) => {
      if (ref.current && !ref.current.contains(event.target as HTMLElement)) {
        setShowSelector(false);
        setFocused(false);
        setHoverIndex(-1);
      }
    };
    document.addEventListener("mousedown", onOutsideClicked);
    return () => document.removeEventListener("mousedown", onOutsideClicked);
  });

  useEffect( () => {
    const handleResize = () => {
      if (Math.round(window?.visualViewport?.height ?? 0) === window.innerHeight) {
        inputRef?.current?.blur();
        setShowSelector(false);
      }
    }
    return window?.visualViewport?.addEventListener('resize', handleResize);
  });

  useEffect( () => {
    if (query.length === 0 ) return;
    const debounceTimeout = setTimeout( () => {
      dispatch(searchSong({page: 0, name: query }))
        .then( ({ payload }: any) => {
          setSongs(payload.items.slice(0, MAX_ITEM_SHOW));
        });
      if (!(query in searchedNames)) {
        dispatch(searchCharacter({ page: 0, name: query }))
          .catch(addErrorToast);
      };
    }, DEBOUCE_TIMER);
    return () => clearTimeout(debounceTimeout);
  }, [query, searchedNames, addErrorToast, dispatch]);

  const selectedCharacterIds: ID[] = useMemo(() => searchQuery.characters.map( (item) => item.id ), [searchQuery]);
  const selectedSongIds: ID[] = useMemo(() => searchQuery.songs.map( (item) => item.id ), [searchQuery]);
  const selectedItemsIds: SelectedId[] = [
    ...selectedCharacterIds.map( id => ({ type: ItemType.CHARACTER, id })),
    ...selectedSongIds.map( id => ({ type: ItemType.SONG, id })),
  ];

  const searchItems: SearchItem[] = useMemo(() => {
    const filteredCharacters: CharacterInfo[] = (Object.values(characters) as CharacterInfo[])
      .filter( character => character.name.toLowerCase().includes(query.toLowerCase()) && !selectedCharacterIds.includes(character.id) );
    const filteredSongs: SongInfo[] = songs.filter( song => !selectedSongIds.includes(song.id) );
    return [
      ...filteredCharacters.map( item => ({ type: ItemType.CHARACTER, name: item.name, item })),
      ...filteredSongs.map( item => ({ type: ItemType.SONG, name: item.title, item }))
    ].sort( (a, b) => a.name.localeCompare(b.name) ).slice(0, MAX_ITEM_SHOW);
  }, [characters, songs, query, selectedCharacterIds, selectedSongIds]);

  const onRefocus = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    document.getElementsByClassName('header_container')[0].scrollIntoView();
  }

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(searchMedia({ ...searchQuery, name: query }))
      .catch(addErrorToast);
    setShowSelector(false);
    setFocused(false);
    setHoverIndex(-1);
    document.getElementsByClassName('header_container')[0].scrollIntoView();
    return false;
  };

  const change = useCallback((searchQuery: MediaSearchQuery) => {
    dispatch(changeSearchQuery(searchQuery))
      .catch(addErrorToast);
  }, [dispatch, addErrorToast]);

  const onChangeQuery = (value: string) => {
    setQuery(value);
    if (!showSelector) setShowSelector(true);
  }

  const onHasFocus = (isFocused: boolean) => {
    setShowSelector(isFocused);
    setFocused(isFocused);
  }

  const onDeselectCharacterItem = (removeId: ID) => {
    change({ ...searchQuery, characters: selectedCharacterIds.filter( (id: ID) => id !== removeId ).map( id => ({ id })) });
    onRefocus();
  };

  const onDeselectSongItem = (removeId: ID) => {
    change({ ...searchQuery, songs: selectedSongIds.filter( (id: ID) => id !== removeId ).map( id => ({ id })) });
    onRefocus();
  }

  const onDeselectLastItem = () => {
    if (selectedItemsIds.length === 0) return;
    const lastSelectedItem = selectedItemsIds[selectedItemsIds.length - 1];
    if (lastSelectedItem.type === ItemType.CHARACTER) {
      onDeselectCharacterItem(lastSelectedItem.id);
    } else if (lastSelectedItem.type === ItemType.SONG) {
      onDeselectSongItem(lastSelectedItem.id);
    }
  }

  const onSelectItem = ({type, item}: SearchItem) => {
    switch(type) {
    case ItemType.CHARACTER:
      const characters = Array.from(new Set([...searchQuery.characters, { id: item.id } ]));
      dispatch(changeSearchQuery({ ...searchQuery, characters }))
      break;
    case ItemType.SONG:
      const songs = Array.from(new Set([...searchQuery.songs, { id: item.id }]));
      dispatch(changeSearchQuery({ ...searchQuery, songs }));
      break;
    }
    setQuery('');
    onRefocus();
  }

  const onKeyDown = async (event: KeyboardEvent) => {
    if (event.keyCode === 40) { // Down
      await setHoverIndex(Math.min(hoverIndex + 1, searchItems.length - 1));
      event.preventDefault();
    } else if (event.keyCode === 38) { // Up
      await setHoverIndex(Math.max(0, hoverIndex - 1));
      event.preventDefault();
    } else if (event.keyCode === 9 && hoverIndex >= 0) { // Tab
      onSelectItem(searchItems[hoverIndex]);
      await setHoverIndex(Math.min(hoverIndex, searchItems.length - 2));
      await setQuery('');
      event.preventDefault();
    } else if (event.keyCode === 8 && query.length === 0) { // Backspace
      onDeselectLastItem();
    }
    (document.getElementsByClassName('character_search_float_container_item_hover')[0] as HTMLElement)?.scrollIntoView();
    inputRef.current?.focus();
  }

  const onHover = (index: number) => {
    setHoverIndex(index);
  }

  const tokens: Token[] = [
    ...selectedCharacterIds.map( (id: ID) => (
      <div key={'c' + id} className="search_token">
        <img className="search_token_icon" alt={characters[id].name} src={characters[id].thumbnailUrl} onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.src = '/assets/icons/character.svg';
        }} />
        {characters[id].name}
        <Icon name="x" color={IconColor.RED} onClick={() => onDeselectCharacterItem(id)} className="search_token_icon" />
      </div>)),
    ...selectedSongIds.map( (id: ID) => {
      const songInfo = songs.find( song => song.id === id );
      return (<div key={'s' + id} className="search_token ">
        <Icon name="music-note-beamed" color={IconColor.GREY} className="search_token_icon" />
        <div>
          <div className="search_token_song_title">{songInfo?.title ?? ''}</div>
          <div className="search_token_song_artist">{songInfo?.artist ?? ''}</div>
        </div>
        <Icon name="x" color={IconColor.RED} onClick={() => onDeselectSongItem(id)} className="search_token_icon" />
      </div>)}),
  ];

  return (
    <form className="searchbar_top_container" onSubmit={onSubmit} ref={ref}>
        <TokenInput
          inputRef={inputRef}
          tokens={tokens}
          placeholder="Search"
          iconName="search"
          className={"searchbar_input " + (isFocused ? "searchbar_input_focused" : "searchbar_input_unfocused") }
          value={query}
          isFocused={isFocused}
          onHasFocus={onHasFocus}
          onKeyDown={onKeyDown}
          onChange={onChangeQuery}
          prompt={ (showSelector ? <span className="searchbar_input_prompt">Press <span className="searchbar_input_prompt_key">TAB</span> to add</span> : <span />) } />
        <div className="searchbar_floater">
          <SearchDropdown
            origins={origins}
            searchItems={searchItems}
            selectedCharacterIds={selectedCharacterIds}
            selectedSongIds={selectedSongIds}
            isShown={showSelector}
            onSelectItem={onSelectItem}
            onHover={onHover}
            onHasFocus={onHasFocus}
            hoverIndex={hoverIndex} />
        </div>
        <input type="submit" style={{display: 'none'}} />
    </form>
  );
};

export default MediaSearch;