import React, { useState, FormEvent } from 'react';
import { useSelector } from 'model/store';
import { selectUser } from 'model/reducers/userSlice';

import Icon, { IconColor } from 'components/widgets/icon';
import InputField from 'components/widgets/input_field';
import SubmitButton, { Type } from 'components/widgets/submit_button';
import OptionMenu from 'components/widgets/option_menu';
import './styles.css';

type IProps = {
  name: string,
  onRename: (newName: string) => void,
  onSave: () => void,
  onDelete: () => void,
  onToggle: (value: boolean) => void,
  isOpen: boolean,
}

function PlaylistHeader({name, onRename, onSave, onDelete, onToggle, isOpen}: IProps) {
  const [showEdit, setShowEdit] = useState(false);
  const [newName, setName] = useState(name);
  const { isLoggedIn } = useSelector(selectUser);

  const onEditRename = () => {
    setShowEdit(true);
  }

  const onCancelEditRename = () => {
    setShowEdit(false);
  }

  const onChangeName = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onRename(newName);
    setShowEdit(false);
    return false;
  }

  const options = isLoggedIn ? [{
      icon: <Icon name="box-arrow-in-up" color={IconColor.GREEN} alt="save" title="Save" className="playlist_header_icon" />,
      title: "Save",
      action: onSave,
    }, {
      icon: <Icon name="pencil" color={IconColor.YELLOW} alt="rename" title="Rename" className="playlist_header_icon" />,
      title: "Rename",
      action: onEditRename,
    }, {
      icon: <Icon name="trash" color={IconColor.RED} alt="delete" title="Remove" className="playlist_header_icon" />,
      title: "Remove",
      action: onDelete,
  }] : [{
      icon: <Icon name="trash" color={IconColor.RED} alt="delete" title="Remove" className="playlist_header_icon" />,
      title: "Remove",
      action: onDelete,
  }];

  const onToggleClick = () => {
    onToggle(!isOpen);
  }

  return (
    <div className="playlist_header_container">
      <div className="playlist_header_content">
        <Icon
          className="playlist_header_icon playlist_header_content_toggle_icon"
          color={IconColor.GREY}
          name={isOpen ? "chevron-right" : "chevron-left"}
          alt="backToggle"
          onClick={onToggleClick} />
        { !showEdit && <h3>{name}</h3> }
        { showEdit && <form className="playlist_header_name_input_container" onSubmit={onChangeName}>
          <InputField iconName="music-note-list" className="playlist_header_name_input" value={newName} onChange={setName} />
          <SubmitButton className="playlist_header_name_input_submit" type={Type.SAVE} />
          <SubmitButton className="playlist_header_name_input_submit" type={Type.CANCEL} onClick={onCancelEditRename} />
        </form>}
      </div>
      <div className="playlist_header_options">
        <OptionMenu options={options} className="" />
      </div>
    </div>
  );
}

export default PlaylistHeader;