import React from 'react';
import { useSelector, useDispatch } from 'model/store';
import { StrID } from 'model/types/api.types';
import { selectPlaylist, choosePlaylist } from 'model/reducers/playlistSlice';

import useToast from 'components/widgets/toast/useToast';
import PlaylistNew from 'components/playlist/new';
import PlaylistMenuItem from './playlistMenuItem';
import './styles.css';

type IProps = {
  onTogglePlaylist: (isOpen?: boolean) => void,
}

function PlaylistMenu({ onTogglePlaylist }: IProps) {
  const dispatch = useDispatch();
  const { list, current } = useSelector(selectPlaylist);
  const { addErrorToast } = useToast();

  const onSelect = (id: StrID) => {
    dispatch(choosePlaylist({ id }))
      .then( () => onTogglePlaylist())
      .catch(addErrorToast);
  }

  const onShowEditOpen = () => {
    onTogglePlaylist(false);
  }

  return (
    <div className="playlist_menu_container">
      <div className="playlist_menu_header">
        <h4>Select Playlist</h4>
      </div>
      <div className="playlist_menu_content">
        { list.map( (playlist) => <PlaylistMenuItem
            key={playlist.id}
            isSelected={playlist.id === current}
            playlist={playlist}
            onSelect={onSelect} />
        )}
        { list?.length === 0 && <div className="playlist_media_list_empty" style={{ height: '2rem' }}>
          <div className="playlist_media_list_empty_content">No Playlist found</div>
        </div>}
        <PlaylistNew onShowEdit={onShowEditOpen}/>
      </div>
    </div>
  );
}

export default PlaylistMenu;