import React from 'react';
import { useDispatch, useSelector } from 'model/store';
import { StrID, Media } from 'model/types/api.types';
import { selectPlaylist, addToPlaylist } from 'model/reducers/playlistSlice';
import useToast from 'components/widgets/toast/useToast';

import Modal from 'components/widgets/modal';
import PlaylistNew from 'components/playlist/new';
import PlaylistMenuItem from './playlistMenuItem';

type IProps = {
  isShown: boolean,
  onHide: () => void,
  media: Media,
}

function PlaylistSelectModal({ isShown, onHide, media }: IProps) {
  const dispatch = useDispatch();
  const { list } = useSelector(selectPlaylist);
  const { addErrorToast, addInfoToast } = useToast();

  const onAddToPlaylist = (playlistId: StrID) => {
    dispatch(addToPlaylist({ playlistId, media }))
      .catch(addErrorToast);
    addInfoToast("Added to Playlist");
    onHide();
  }

  return (
    <Modal isShown={isShown} onHide={onHide}>
      <div className="playlist_select_container">
        <div>
          <h3>Playlists</h3>
        </div>
        { list.map( (playlist) => <PlaylistMenuItem key={playlist.id} playlist={playlist} onSelect={() => onAddToPlaylist(playlist.id)} />)}
        { list?.length === 0 && <div className="playlist_media_list_empty" style={{ height: '2rem' }}>
          <div className="playlist_media_list_empty_content">No Playlist found</div>
        </div>}
        <PlaylistNew onShowEdit={() => {}} />
      </div>
    </Modal>
  );
}

export default PlaylistSelectModal;