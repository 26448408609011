import React from 'react';
import { useDispatch } from 'model/store';
import { actions as mediaActions } from 'model/reducers/mediaSlice';
import Modal from 'components/widgets/modal';
import MediaNew from './new';

type IProps = {
  isShown: boolean,
  toggleShown: (isShown :boolean) => void,
}

function MediaNewModal({ isShown, toggleShown }: IProps) {
  const dispatch = useDispatch();

  const onDone = () => {
    dispatch(mediaActions.clearAddedMedia());
    toggleShown(false);
  }
  
  return (
    <Modal
        isShown={isShown}
        onHide={onDone}>
      <MediaNew onDone={onDone} />
    </Modal>
  );
}

export default MediaNewModal;