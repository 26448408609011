import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import {
  get_listCharacterOrigin,
  get_searchCharacter,
  get_searchCharacterById,
} from 'model/apis/apis';
import { ID, PageItems, PageRequest, CharacterInfo, CharacterOrigin } from 'model/types/api.types';
import { CharacterState } from 'model/types/character.types';
import { RootState } from 'model/store';

type NameParams = PageRequest & {
  name: string;
}

type IdParams = {
  ids: ID[];
}

type CharacterParms = {
  characters: CharacterInfo[];
}

type CharacterCache = {
  name: string;
  characters: PageItems<CharacterInfo>;
}

const initialState: CharacterState = {
  origins: {},
  characters: {},
  searchedNames: {},
  isLoading: false,
}

export const listCharacterOrigins = createAsyncThunk('/characters/origin/list', async ({ page = 0 }: PageRequest) => {
  return await get_listCharacterOrigin(page);
});

export const searchCharacter = createAsyncThunk('/characters/search', async ({ page, name }: NameParams) => {
  const characters = await get_searchCharacter(page, name);
  return { name, characters };
});

export const searchCharacterMore = createAsyncThunk('/characters/search/more', async ({ page, name }: NameParams) => {
  const characters = await get_searchCharacter(page, name);
  return { name, characters };
});

export const listCharacterByIds = createAsyncThunk('/character/list/ids', async ({ ids }: IdParams) => {
  return await get_searchCharacterById(ids);
});

export const addCharactersToList = createAsyncThunk('/characters/list/add', async ({ characters }: CharacterParms) => {
  return { items: characters, pageInfo: { hasNext: false, page: 1, total: characters.length } };
});

const loadPending = (state: CharacterState) => {
  state.isLoading = true;
}

const loadFinished = (state: CharacterState) => {
  state.isLoading = false;
}

const loadRejected = (state: CharacterState, payload: any) => {
  state.isLoading = false;
  throw new Error(payload.error.message);
}

const loadCharacterPayloadToObject = (state: CharacterState, payload: PageItems<CharacterInfo>) => {
  (payload.items ?? []).forEach( (character: CharacterInfo) => {
    state.characters[character.id] = character;
  })
}

export const characterSlice = createSlice({
  name: 'characterState',
  initialState,
  reducers: {},
  extraReducers(builder) {
      builder
        .addCase(listCharacterOrigins.pending, loadPending)
        .addCase(listCharacterOrigins.fulfilled, (state: CharacterState, { payload }: PayloadAction<PageItems<CharacterOrigin>>) => {
          state.origins = (payload.items ?? []).reduce( (map: any, origin: any) => {
            map[origin.id] = origin;
            return map;
          }, {});
          loadFinished(state);
        }).addCase(listCharacterOrigins.rejected, loadRejected)
        .addCase(searchCharacter.pending, loadPending)
        .addCase(searchCharacter.fulfilled, (state: CharacterState, { payload }: PayloadAction<CharacterCache>) => {
          loadCharacterPayloadToObject(state, payload?.characters);
          state.searchedNames[payload.name] = payload?.characters.pageInfo;
          loadFinished(state);
        }).addCase(searchCharacter.rejected, loadRejected)
        .addCase(searchCharacterMore.pending, loadPending)
        .addCase(searchCharacterMore.fulfilled, (state: CharacterState, { payload }: PayloadAction<CharacterCache>) => {
          loadCharacterPayloadToObject(state, payload?.characters);
          state.searchedNames[payload.name] = payload?.characters.pageInfo;
          loadFinished(state);
        }).addCase(searchCharacterMore.rejected, loadRejected)
        .addCase(listCharacterByIds.pending, loadPending)
        .addCase(listCharacterByIds.fulfilled, (state: CharacterState, { payload }) => {
          loadCharacterPayloadToObject(state, payload);
          loadFinished(state);
        }).addCase(listCharacterByIds.rejected, loadRejected)
        .addCase(addCharactersToList.fulfilled, (state: CharacterState, { payload }: PayloadAction<PageItems<CharacterInfo>>) => {
          loadCharacterPayloadToObject(state, payload);
        });
  },
});

export const selectCharacter = (state: RootState): CharacterState => {
  return state.characterState;
}

export default characterSlice.reducer;