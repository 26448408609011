import { ORIGIN_PAGE_SIZE, PAGE_SIZE, RANDOM_PAGE_COUNT } from 'model/constants/sizes';
import {
  postAuthRequest,
  deleteAuthRequest,
  getAuthRequest,
  postRequestPagination,
  getRequest,
  getRequestPagination,
  getAuthRequestPagination,
} from './useApi';
import {
  ID,
  Token,
  Cursor,
  PageItems,

  CharacterRequest,
  SongRequest,
  TagRequest,
  MediaRequest,
  MediaIdsRequest,
  MediaSearchQuery,
  NameRequest,

  UserInfo,
  CharacterOrigin,
  CharacterInfo,
  Media,
  SongInfo,
  Playlist,
  Tags,
  Tag,
} from 'model/types/api.types';

export const post_userInfo = async (authToken: Token): Promise<UserInfo> => {
  return postAuthRequest<UserInfo>('/userinfo/get', authToken);
}

export const post_createMedia = async (authToken: Token, { mediaSourceId }: MediaRequest): Promise<Media> => {
  return postAuthRequest<Media>('/media/new', authToken, { mediaSourceId });
}

export const post_deleteMedia = async (authToken: Token, { mediaSourceId }: MediaRequest): Promise<Media> => {
  return postAuthRequest<Media>('/media/delete', authToken, { mediaSourceId });
}

export const post_editSong = async (authToken: Token, song: SongInfo): Promise<SongInfo> => {
  return postAuthRequest<SongInfo>('/media/song/add', authToken, song);
}

export const post_addCharacter = async (authToken: Token, character: CharacterRequest): Promise<CharacterInfo[]> => {
  return postAuthRequest<CharacterInfo[]>('/media/character/edit', authToken, character);
}

export const post_tag = async (authToken: Token, tags: TagRequest): Promise<Tags> => {
  return postAuthRequest<Tags>('/media/tag', authToken, tags);
}

export const post_searchMedia = async (page: Cursor, searchQuery: MediaSearchQuery): Promise<PageItems<Media>> => {
  return postRequestPagination<Media>('/media/search', page, PAGE_SIZE, searchQuery);
}

export const get_listMedia = async (page: Cursor): Promise<PageItems<Media>> => {
  return getRequestPagination<Media>('/media/list', page, PAGE_SIZE);
}

export const get_randomMedia = async (mediaIds: ID[]): Promise<PageItems<Media>> => {
  return getRequestPagination<Media, MediaIdsRequest>('/media/random', 1, RANDOM_PAGE_COUNT, { mediaIds });
}

export const get_searchSong = async (page: Cursor, title: string, artist: string): Promise<PageItems<SongInfo>> => {
  return getRequestPagination<SongInfo, SongRequest>('/song/search', page, PAGE_SIZE, { title, artist });
}

export const get_searchCharacter = async (page: Cursor, name: string): Promise<PageItems<CharacterInfo>> => {
  return getRequestPagination<CharacterInfo, NameRequest>('/character/search', page, PAGE_SIZE, { name });
}

export const get_searchCharacterById = async (ids: ID[]): Promise<PageItems<CharacterInfo>> => {
  return getRequest<PageItems<CharacterInfo>>('/character/search/id', { id: ids });
}

export const get_listCharacterOrigin = async (page: Cursor): Promise<PageItems<CharacterOrigin>> => {
  return getRequestPagination<CharacterOrigin>('/character/origin/list', page, ORIGIN_PAGE_SIZE);
}

export const get_searchTag = async (page: Cursor, name: string): Promise<PageItems<Tag>> => {
  return getRequestPagination<Tag>('/tag/search', page, PAGE_SIZE, name);
}

export const post_newPlaylist = async (authToken: Token, name: string): Promise<Playlist> => {
  return postAuthRequest<Playlist>('/playlist/new', authToken, { name, media: [] });
}

export const delete_playlist = async (authToken: Token, id: ID): Promise<boolean> => {
  return deleteAuthRequest<boolean>('/playlist/delete', authToken, { id });
}

export const get_playlists = async (authToken: Token): Promise<PageItems<Playlist>> => {
  return getAuthRequest<PageItems<Playlist>>('/playlist/list', authToken);
}

export const post_renamePlaylist = async (authToken: Token, id: ID, name: string): Promise<Playlist> => {
  return postAuthRequest('/playlist/rename', authToken, { id, name, media: [] });
}

export const post_savePlaylist = async (authToken: Token, playlist: Playlist): Promise<Playlist> => {
  return postAuthRequest('/playlist/edit', authToken, playlist);
}

export const get_favourites = async (authToken: Token): Promise<Playlist> => {
  return getAuthRequest<Playlist>('/favourite/list', authToken);
}

export const post_favourite = async (authToken: Token, mediaId: ID): Promise<boolean> => {
  return postAuthRequest<boolean>('/favourite/add', authToken, { mediaId });
}

export const delete_favourites = async(authToken: Token, mediaId: ID): Promise<boolean> => {
  return deleteAuthRequest<boolean>('/favourite/delete', authToken, { mediaId });
} 

/* ADMIN REQUESTS */
export const get_listUser = async (authToken: Token, searchId: string, page: Cursor): Promise<PageItems<UserInfo>> => {
  return getAuthRequestPagination<UserInfo>('/users/search', authToken, page, PAGE_SIZE, { searchId });
}

export const post_grantPermission = async (authToken: Token, user: UserInfo): Promise<UserInfo> => {
  return postAuthRequest<UserInfo>('/users/grant', authToken, user);
}

export const post_editCharacterOrigin = async (authToken: Token, origin: CharacterOrigin): Promise<CharacterOrigin> => {
  return postAuthRequest<CharacterOrigin>('/character/origin/edit', authToken, origin);
}

export const post_newCharacterOrigin = async (authToken: Token, origin: CharacterOrigin): Promise<CharacterOrigin> => {
  return postAuthRequest<CharacterOrigin>('/character/origin/new', authToken, origin);
}

export const post_editCharacter = async(authToken: Token, character: CharacterInfo): Promise<CharacterInfo> => {
  return postAuthRequest<CharacterInfo>('/character/edit', authToken, character);
}

export const post_newCharacter = async(authToken: Token, character: CharacterInfo): Promise<CharacterInfo> => {
  return postAuthRequest<CharacterInfo>('/character/new', authToken, character);
}

export const post_mediaScrape = async(authToken: Token, id: string): Promise<void> => {
  return postAuthRequest<void>('/media/scrape/channel', authToken, { id });
}