import React, { useState, useMemo, MouseEvent, FormEvent } from 'react';
import { useDispatch } from 'model/store';
import { StrID, Playlist } from 'model/types/api.types';
import {
  exportPlaylist,
  deletePlaylist,
  renamePlaylist,
} from 'model/reducers/playlistSlice';

import useToast from 'components/widgets/toast/useToast';
import Icon, { IconColor } from 'components/widgets/icon';
import InputField from 'components/widgets/input_field';
import SubmitButton, { Type } from 'components/widgets/submit_button';
import OptionMenu from 'components/widgets/option_menu';
import './styles.css';

type IProps = {
  playlist: Playlist,
  isSelected?: boolean,
  onSelect: (id: StrID) => void,
}

function PlaylistMenuItem({ playlist, isSelected = false, onSelect }: IProps) {
  const dispatch = useDispatch();
  const [newName, setName] = useState(playlist.name);
  const [editRename, setEditRename] = useState(false);
  const { addErrorToast } = useToast();

  const onCancelEditRename = (event: FormEvent<HTMLInputElement>) => {
    setEditRename(false);
    event.stopPropagation();
  }

  const onStopPropagation = (event: MouseEvent<HTMLFormElement>) => {
    event.stopPropagation();
  }

  const onRename = (event: FormEvent) => {
    dispatch(renamePlaylist({ id: playlist.id, name: newName }))
      .then(() => setEditRename(false))
      .catch(addErrorToast);
    event.preventDefault();
  }

  const options = useMemo( () => {
    const onEditRename = (event: MouseEvent<HTMLDivElement>) => {
      setEditRename(true);
      event.stopPropagation();
    }
    const onSave = (event: MouseEvent<HTMLDivElement>) => {
      dispatch(exportPlaylist({ playlist }))
        .catch(addErrorToast);
      event.stopPropagation();
    }

    const onDelete = (event: MouseEvent<HTMLDivElement>) => {
      dispatch(deletePlaylist({ id: playlist.id }))
        .catch(addErrorToast);
      event.stopPropagation();
    }

    return [{
      icon: <Icon name="box-arrow-in-up" color={IconColor.GREEN} alt="save" title="Save" className="media_item_info_option_icon" />,
      title: "Save",
      action: onSave,
    }, {
      icon: <Icon name="pencil" alt="rename" color={IconColor.YELLOW} title="Rename" className="media_item_info_option_icon" />,
      title: "Rename",
      action: onEditRename,
    }, {
      icon: <Icon name="trash" color={IconColor.RED} alt="delete" title="Remove" className="media_item_info_option_icon" />,
      title: "Remove",
      action: onDelete,
  }]}, [dispatch, playlist, addErrorToast]);

  return (
    <div
        className={"playlist_menu_item_container" + (isSelected ? " playlist_menu_item_selected" : "")}
        onClick={() => onSelect(playlist.id)}>
      <Icon name="play-circle-fill" color={IconColor.BLUE} alt="select" title={playlist.name} />
      {!editRename && <div className="playlist_menu_item_container_title">{playlist.name}</div> }
      {editRename && <form className="playlist_menu_item_edit" onSubmit={onRename} onClick={onStopPropagation}>
        <InputField iconName="music-note-list" className="playlist_menu_item_edit_input" value={newName} onChange={setName} />
        <SubmitButton className="playlist_menu_item_edit_button" type={Type.SAVE} />
        <SubmitButton className="playlist_menu_item_edit_button" type={Type.CANCEL} onClick={onCancelEditRename} />
      </form>}
      { !editRename && <div className="playlist_menu_item_option_container">
        <OptionMenu options={options} />
      </div>}
    </div>
  );
}

export default PlaylistMenuItem;