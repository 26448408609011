import React from 'react';
import { SongInfo } from 'model/types/api.types';
import Icon, { IconColor } from 'components/widgets/icon';

type IProps = {
  song: SongInfo,
  isHover: boolean,
  onSelect: (song: SongInfo) => void,
  onHover: () => void,
}

function SongSearchItem({
  song,
  isHover,
  onHover,
  onSelect,
}: IProps) {

  const onSelectSong = () => {
    onSelect(song);
  }

  return (
    <div className={"song_search_float_item " + (isHover ? "song_search_float_item_hover" : "")}
        onClick={onSelectSong}
        onMouseEnter={onHover}>
      <Icon name="music-note-beamed" color={IconColor.GREY} className="song_search_float_item_icon" />
      <span>{song.title}</span>
      <div className="song_search_float_item_right_text">{song.artist}</div>
    </div>
  );
}

export default SongSearchItem;