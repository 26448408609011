import React, { ReactElement } from 'react';
import './styles.css';

type IProps = {
  icon: ReactElement,
  value: string,
  onSelect: (tab: string) => void,
  isSelected: boolean,
}

function SidebarItem({ icon, value, onSelect, isSelected }: IProps) {

  const onClick = () => {
    onSelect(value);
  }

  return (
    <div onClick={onClick} className={"sidebar_item_container " + (isSelected ? "sidebar_item_container_selected" : "")}>
      {icon}
    </div>
  );
}

export default SidebarItem;