import React, { useState, useEffect, TouchEvent } from 'react';
import { useSelector, useDispatch } from 'model/store';
import { actions as queueActions, selectQueue, selectQueueList, addRandomMedia } from 'model/reducers/queueSlice';
import { actions as uiActions } from 'model/reducers/uiSlice';

import useToast from 'components/widgets/toast/useToast';
import Icon, { IconColor } from 'components/widgets/icon';
import WatchContent from './watchContent';
import './styles.css';

const DEFAULT_WIDTH = 640;

function MediaWatch() {
  const dispatch = useDispatch();
  const [touchPosition, setTouchPosition] = useState<number | null>(null);
  const { currentMedia } = useSelector(selectQueue);
  const { hasNext, hasPrev, autoPlay } = useSelector(selectQueueList);
  const [width, setWidth] = useState(DEFAULT_WIDTH);
  const { addErrorToast } = useToast();

  useEffect( () => {
    const handleResize = () => {
      setWidth(Math.min(window.innerWidth - 50, DEFAULT_WIDTH));
    }
    handleResize();
    return window.addEventListener('resize', handleResize);
  }, []);

  if (currentMedia === null) { return <div /> };

  const onEnd = () => {
    onNext();
  }

  const onCloseWatch = () => {
    dispatch(queueActions.clearMedia());
    dispatch(uiActions.changeWatchSelected(false));
  }

  const onNext = () => {
    if (hasNext) {
      dispatch(queueActions.nextMedia());
      dispatch(uiActions.changeWatchSelected(true));
    } else if (autoPlay) {
      dispatch(addRandomMedia()).then(() => {
        dispatch(queueActions.nextMedia());
      }).catch(addErrorToast);
    }
  }

  const onPrev = () => {
    if (hasPrev) dispatch(queueActions.prevMedia());
  }

  const handleTouchStart = (event: TouchEvent<HTMLDivElement>) => {
    setTouchPosition(event.touches[0].clientX);
    event.preventDefault();
  }

  const handleTouchMove = (event: TouchEvent<HTMLDivElement>) => {
    if (touchPosition === null) return;
    const currentTouch = event.touches[0].clientX;
    if (touchPosition - currentTouch > 0) {
      onNext();
    } else if (touchPosition - currentTouch < 0) {
      onPrev();
    }
    setTouchPosition(null);
  }

  return (
    <div className="media_watch_container"  onTouchStart={handleTouchStart} onTouchMove={handleTouchMove}>
      <div className="media_watch_controls media_watch_controls_left" onClick={onPrev}>
        { hasPrev && <Icon name="chevron-left" className="media_watch_controls_icon" color={IconColor.GREY} alt="previous" /> }
      </div>
      <WatchContent media={currentMedia} onEnd={onEnd} width={width} />
      <div className="media_watch_controls media_watch_controls_right" onClick={onNext}>
        { hasNext && <Icon name="chevron-right" className="media_watch_controls_icon" color={IconColor.GREY} alt="next" /> }
      </div>
      <div className="media_watch_close" onClick={onCloseWatch}>
        <Icon name="x" color={IconColor.GREY} className="media_watch_close_icon" alt="close" />
      </div>
    </div>
  );
}

export default MediaWatch;