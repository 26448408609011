import React, { useState, useRef, DragEvent } from 'react';
import { useDispatch } from 'model/store';
import { Playlist as Playlist_Type, Media } from 'model/types/api.types';
import { 
  reorderPlaylist,
  exportPlaylist,
  deletePlaylist,
  removeFromPlaylist,
  renamePlaylist,
} from 'model/reducers/playlistSlice';
import { actions as queueActions } from 'model/reducers/queueSlice';
import { actions as uiActions } from 'model/reducers/uiSlice';

import useToast from 'components/widgets/toast/useToast';
import PlaylistHeader from 'components/playlist/header';
import MediaItem from 'components/playlist/compact_media';

import './styles.css';

type IProps = {
  playlist: Playlist_Type,
  onToggle: (isOpen: boolean) => void,
  isOpen: boolean,
}

function Playlist({ playlist, onToggle, isOpen }: IProps) {
  const dispatch = useDispatch();
  const ref = useRef<HTMLDivElement>(null);
  const [ draggedItem, setDraggedItem ] = useState<HTMLElement | null>(null);
  const [ oldIndex, setOldIndex ] = useState(0);
  const [ newIndex, setNewIndex ] = useState(0);
  const { addErrorToast } = useToast();

  if (playlist !== undefined && (playlist.id ?? -1) < 0) return <></>;

  const onDragStart = (event: DragEvent<HTMLDivElement>) => {
    event.dataTransfer.effectAllowed = "move";
    const element = event.currentTarget.parentElement;
    element?.classList.add('playlist_media_dragged');
    setDraggedItem(element);
  }

  const onDrag = async (event: DragEvent<HTMLDivElement>) => {
    let swapItem = document.elementFromPoint(event.clientX, event.clientY)?.closest('.compact_media_item_container');
    if (swapItem === null || swapItem === draggedItem) return;

    const siblings = Array.from(ref.current?.children ?? []);
    const oldIndex = siblings.indexOf(draggedItem as HTMLElement);
    const newIndex = siblings.indexOf(swapItem as HTMLElement);
    setOldIndex(oldIndex);
    setNewIndex(newIndex);
  }

  const onDrop = (event: DragEvent<HTMLDivElement>) => {
    draggedItem?.classList.remove('playlist_media_dragged');
    dispatch(reorderPlaylist({ oldIndex, newIndex }))
      .catch(addErrorToast);
    setDraggedItem(null);
  }

  const onSave = () => {
    dispatch(exportPlaylist({ playlist: { ...playlist } }))
      .catch(addErrorToast);
  }

  const onRemove = () => {
    dispatch(deletePlaylist({ id: playlist.id }))
      .catch(addErrorToast);
    onToggle(false);
  }

  const onRename = (value: string) => {
    dispatch(renamePlaylist({ id: playlist.id, name: value }))
      .catch(addErrorToast);
  }

  const onRemoveMedia = (media: Media) => {
    dispatch(removeFromPlaylist({ playlistId: playlist.id, mediaId: media.id }))
      .catch(addErrorToast);
  }

  const onWatch = (playlist: Playlist_Type, mediaIndex: number) => {
    dispatch(queueActions.choosePlaylistMedia({ playlist, mediaIndex }));
    dispatch(uiActions.changeWatchSelected(true));
  }

  if (playlist === undefined) return <div />

  return (
    <>
      <PlaylistHeader name={playlist.name} onRename={onRename} onSave={onSave} onDelete={onRemove} onToggle={onToggle} isOpen={isOpen} />
      <div className="playlist_media_list_container" ref={ref}>
        {(playlist.media || []).map( (media, index) => <MediaItem
          key={media.id}
          media={media}
          onDragStart={onDragStart}
          onDrag={onDrag}
          onDrop={onDrop}
          onRemove={() => onRemoveMedia(media)}
          onWatch={() => onWatch(playlist, index)}
        />)}
        {(playlist.media || []).length === 0 &&
          <div className="playlist_media_list_empty">
            <div className="playlist_media_list_empty_content">Add videos to playlist</div>
          </div>}
      </div>
    </>
  );
}

export default Playlist;