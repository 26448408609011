import React from 'react';
import './styles.css';

type IProps = {
	children: JSX.Element[],
}

function Container({ children }: IProps) {
	return (<>{children}</>);
}

export default Container;