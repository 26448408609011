import React, { MouseEvent } from 'react';
import './icon_colors.css';

export enum IconColor {
  BLACK = 'black',
  WHITE = 'white',
  GREY = 'grey',
  GREYER = 'greyer',
  LIGHT_GREY = 'light_grey',
  RED = 'red',
  GREEN = 'green',
  YELLOW = 'yellow',
  BLUE = 'blue',
  AZURE = 'azure',
  PURPLE = 'purple',
  DISCORD_PURPLE = 'discord_purple',
}

type IProps = {
  name: string,
  className?: string,
  alt?: string,
  title?: string,
  color?: IconColor,
  onClick?: (event: MouseEvent) => void,
}

function Icon({ name, className = "", alt, title, color = IconColor.BLACK, onClick }: IProps) {

  return (
    <img
      alt={alt}
      title={title}
      src={`/assets/icons/${name}.svg`}
      className={`${className} icon_color_${color}`}
      onClick={onClick}
    />
  );
}

export default Icon;