import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { post_userInfo } from 'model/apis/apis';
import { RootState } from 'model/store';
import { Token, UserInfo } from 'model/types/api.types';
import { PERMISSION, hasPermission } from 'model/constants/permission';
import { UserState, UserPrefences, UserPermissions } from 'model/types/user.types';

type LoginParams = {
  token: Token;
}

const initialState: UserState = {
  isLoading: false,
  isLoggedIn: false,
  preferences: {
    editMode: false,
    showTitle: true,
  },
  userInfo: {
    token: '',
    email: '',
    avatarUrl: '',
    permissions: '0000000',
  },
  permissions: {
    canEdit: false,
    canRemove: false,
    canTag: false,
  }
};

export const userLogin = createAsyncThunk('/user/info', async({ token }: LoginParams) => {
  const userInfo: UserInfo = await post_userInfo(token);
  return {
    token,
    ...userInfo,
  }
});

const setPermissions = (state: UserState, permissions: string) => {
  if (permissions?.length) {
    state.permissions.canEdit = hasPermission(permissions, PERMISSION.BASIC_WRITE) || hasPermission(permissions, PERMISSION.ADVANCE_WRITE);
    state.permissions.canRemove = hasPermission(permissions, PERMISSION.BASIC_DELETE);
    state.permissions.canTag = hasPermission(permissions, PERMISSION.WRITE_TAG);
  }
}

const loadRejected = (state: UserState, { error }: any) => {
  state.isLoading = false;
  throw new Error(error.message);
}

export const userSlice = createSlice({
  name: 'userState',
  initialState,
  reducers: {
    changeEditMode(state: UserState, { payload }) {
        state.preferences.editMode = state.permissions.canEdit && payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(userLogin.pending, (state: UserState) => {
        state.isLoading = false;
      }).addCase(userLogin.fulfilled, (state: UserState, { payload }: PayloadAction<UserInfo>) => {
        state.isLoading = false;
        state.isLoggedIn = true;
        state.userInfo = { ...payload };
        setPermissions(state, payload?.permissions);
      }).addCase(userLogin.rejected, loadRejected);
  },
});

export const selectUser = (state: RootState): UserState => {
  return state.userState;
}

export const selectUserInfo = (state: RootState): UserInfo => {
  return selectUser(state).userInfo;
}

export const selectUserPreference = (state: RootState): UserPrefences => {
  return selectUser(state).preferences;
}

export const selectUserPermisisons = (state: RootState): UserPermissions => {
  return selectUser(state).permissions;
}

export const { actions } = userSlice;

export default userSlice.reducer;