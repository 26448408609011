import React, { useState, useRef, useEffect, ReactElement, MouseEvent } from 'react';

import Icon, { IconColor } from 'components/widgets/icon';
import OptionMenuItem from './optionMenuItem';
import './styles.css';

export type OptionItem = {
  title: string,
  icon: ReactElement,
  action: (event: MouseEvent<HTMLDivElement>) => void,
}

type IProps = {
  options: OptionItem[],
  className?: string,
}

function OptionMenu({ options, className = '' }: IProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const onOutsideClicked = (event: Event) => {
      if (ref.current && !ref.current.contains(event.target as HTMLElement)) {
        setMenuOpen(false);
      }
    }
    document.addEventListener("mousedown", onOutsideClicked);
    return () => document.removeEventListener("mousedown", onOutsideClicked);
  }, [setMenuOpen]);

  const onMenuClick = (event: MouseEvent) => {
    setMenuOpen(!menuOpen);
    event.stopPropagation();
  }

  const onMenuClose = () => {
    setMenuOpen(false);
  }

  return (
    <div className={"option_menu_container " + className}>
      <Icon name="three-dots-vertical" color={IconColor.GREY} alt="options" onClick={onMenuClick} />
      {menuOpen && <div className="option_menu_floater" ref={ref}>
        <Icon name="three-dots-vertical" alt="options" onClick={onMenuClick} />
        {(options ?? []).map( (item, index) => <OptionMenuItem key={index} option={item} onSelect={onMenuClose} />)}
      </div>}
    </div>
  );
}

export default OptionMenu;