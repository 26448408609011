import React, { useState } from 'react';
import { useSelector } from 'model/store';
import { selectUserInfo } from 'model/reducers/userSlice';
import { PERMISSION, hasPermission } from 'model/constants/permission';

import Menubar from 'components/menubar';
import MenuItem from 'components/menubar/menuItem';
import User from 'components/user';
import CharacterConsole from './characters';
import OriginConsole from './characters/originConsole';
import UserConsole from './users';
import MediaScrapperConsole from './media';
import './styles.css';

enum Tabs {
  NONE = 0,
  USERS = 1,
  ORIGIN = 2,
  CHARACTERS = 3,
  SCRAPPER = 4,
}

function AdminConsole() {
  const [selectedTab, setSelectedTab] = useState(Tabs.NONE);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const userInfo = useSelector(selectUserInfo);
  const authToken = userInfo.token ?? '';

  const onMenuOpen = (isOpen: boolean) => {
    setMenuOpen(isOpen);
  }

  const onSelectMenuItem = (selectedTab: Tabs) => {
    setSelectedTab(selectedTab);
  }

  return (
    <div>
      <div className={"admin_console_content " + (isMenuOpen ? "admin_console_content_offset" : "") }>
        {selectedTab === Tabs.NONE && <div />}
        {selectedTab === Tabs.USERS && <UserConsole authToken={authToken} />}
        {selectedTab === Tabs.ORIGIN && <OriginConsole authToken={authToken}  />}
        {selectedTab === Tabs.CHARACTERS && <CharacterConsole authToken={authToken} />}
        {selectedTab === Tabs.SCRAPPER && <MediaScrapperConsole authToken={authToken} />}
      </div>
      <div className="admin_console_header">
        <Menubar onOpen={onMenuOpen}>
          {hasPermission(userInfo.permissions, PERMISSION.ADVANCE_WRITE) && <MenuItem label="Users" title="Users" onClick={() => onSelectMenuItem(Tabs.USERS)} />}
          {hasPermission(userInfo.permissions, PERMISSION.ADVANCE_WRITE) && <MenuItem label="Origin" title="Origin" onClick={() => onSelectMenuItem(Tabs.ORIGIN)} />}
          {hasPermission(userInfo.permissions, PERMISSION.ADMIN        ) && <MenuItem label="Characters" title="Characters" onClick={() => onSelectMenuItem(Tabs.CHARACTERS)} />}
          {hasPermission(userInfo.permissions, PERMISSION.ADMIN        ) && <MenuItem label="Media" title="Characters" onClick={() => onSelectMenuItem(Tabs.SCRAPPER)} />}
        </Menubar>
        <h2 className="admin_console_title">Admin Console</h2>
        <User />
      </div>
    </div>
  );
}

export default AdminConsole;