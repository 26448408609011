import React, { MouseEvent, KeyboardEvent, ChangeEvent, RefObject } from 'react';
import Icon, { IconColor } from 'components/widgets/icon';
import './styles.css';

export type Token = JSX.Element;

type IProps = {
  tokens: Token[],
  value: string | number,
  onChange: (value: string) => void,
  isFocused: boolean,
  onHasFocus?: (value: boolean) => void,
  onKeyDown?: (event: KeyboardEvent) => void,
  iconName: string,
  placeholder?: string,
  className?: string,
  inputRef?: RefObject<HTMLInputElement>,
  prompt?: JSX.Element,
}

function TokenInput({ tokens, value, onChange, isFocused, onHasFocus = () => {}, onKeyDown = () => {}, iconName, placeholder = '', className = "", inputRef, prompt = <span /> }: IProps) {
  const onClickIcon = (event: MouseEvent) => {
    if (inputRef?.current) {
      inputRef.current.focus();
    }
    event.stopPropagation();
  }

  const onHandleFocus = () => {
    onHasFocus(true);
  }

  const onHandleChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    onChange(event.target.value);
  }

  return (
    <div className={"token_input_container " + className + (isFocused ? " token_input_container_focused" : "") } onFocus={onHandleFocus} >
      <Icon name={iconName} alt="" color={IconColor.GREYER} className="token_input_icon" onClick={onClickIcon} />
      <div className="token_input_token_container">{tokens}</div>
      <input className="token_input_field"
        ref={inputRef}
        placeholder={isFocused ? 'Search' : ''}
        type="text"
        value={value}
        onChange={onHandleChange}
        onKeyDown={onKeyDown} />
      {tokens.length === 0 && <div className="token_input_prompt" onClick={onClickIcon}>{prompt}</div>}
    </div>
  );
}

export default TokenInput;