import React, { useState, useEffect, useRef, ChangeEvent, KeyboardEvent } from 'react';
import { useSelector } from 'model/store';
import { ID, CharacterInfo } from 'model/types/api.types';
import { selectCharacter } from 'model/reducers/characterSlice';

import Icon, { IconColor } from 'components/widgets/icon';
import CharacterSearchFloater from './CharacterSearchFloater';
import './styles.css';

const MAX_SHOW_LETTERS = 18;
const X_LETTER = 5;

const calcuateLetters = (list: CharacterInfo[], character: CharacterInfo) => {
  let letters = 0;
  let shown = 0;
  list.forEach( item => {
    letters += item.name.length + X_LETTER;
    if (letters <= MAX_SHOW_LETTERS) { shown++; }
  });
  if (character.name.length + X_LETTER < MAX_SHOW_LETTERS) shown++;
  return shown;
}

type IProps = {
  ids: ID[],
  onSelect: (ids: ID[]) => void,
  onCancel: () => void,
}

function CharacterSearch({ ids, onSelect, onCancel }: IProps) {
  const ref = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [showSelector, setShowSelector] = useState(false);
  const [name, setName] = useState('');
  const [maxShowSelected, setMaxShowSelected] = useState(0);
  const [hoverIndex, setHoverIndex] = useState(-1);
  const { characters } = useSelector(selectCharacter);

  const selected = new Set(ids);
  const characterValues = Object.values(characters) as CharacterInfo[];
  const unselectedCharacters = characterValues.filter( (character: CharacterInfo) => !selected.has(character.id) && character.name.toLowerCase().includes(name.toLowerCase())).sort( (a, b) => a.id - b.id );
  const selectedCharacters = characterValues.filter( (character: CharacterInfo) => selected.has(character.id)).sort( (a, b) => a.id - b.id );

  useEffect(() => {
    const onOutsideClicked = (event: Event) => {
      if (ref.current && !ref.current.contains(event.target as HTMLElement)) {
        setShowSelector(false);
        setName('');
        setHoverIndex(-1);
      }
    };
    document.addEventListener("mousedown", onOutsideClicked);
    return () => document.removeEventListener("mousedown", onOutsideClicked);
  });

  const onSelectItem = (character: CharacterInfo) => {
    selected.add(character.id);
    setMaxShowSelected(calcuateLetters(selectedCharacters, character));
    onSelect(Array.from(selected));
    onRefocus();
  };
  
  const onDeselectItem = (character: CharacterInfo) => {
    selected.delete(character.id);
    setMaxShowSelected(calcuateLetters(selectedCharacters, character));
    onSelect(Array.from(selected));
    onRefocus();
  };

  const onSelectChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  }

  const onKeyDown = async (event: KeyboardEvent) => {
    if (event.keyCode === 40) { // Down
      await setHoverIndex(Math.min(hoverIndex + 1, unselectedCharacters.length - 1));
    } else if (event.keyCode === 38) {
      await setHoverIndex(Math.max(0, hoverIndex - 1));
    } else if (event.keyCode === 13 && hoverIndex >= 0) { // Enter
      onSelectItem(unselectedCharacters[hoverIndex]);
      await setHoverIndex(Math.min(hoverIndex, unselectedCharacters.length - 2));
      event.preventDefault();
    }
    (document.getElementsByClassName('character_search_float_container_item_hover')[0] as HTMLElement)?.scrollIntoView(false);
    inputRef.current?.focus();
  }

  const onHover = (index: number) => {
    setHoverIndex(index);
  }

  const onRefocus = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }

  const onFocus = () => {
    setShowSelector(true);
  }

  return (
    <div className={"character_search_container " + (showSelector ? "character_search_main_container_full" : "")} ref={ref} onFocus={onFocus} >
      <div className={"character_search_main_container"} onClick={onRefocus}>
        <Icon name="people" alt="characters" color={IconColor.GREYER} className="character_search_icon_character" />
        <input className="character_search_input_text"
          autoFocus
          ref={inputRef}
          placeholder={showSelector ? "Search character" : ""}
          value={name}
          onChange={onSelectChange}
          onKeyDown={onKeyDown} />
        <div className="character_search_input_selected">
          {!showSelector && selectedCharacters.slice(0, maxShowSelected).map( character =>
            <div className="character_search_input_selected_item" onClick={onRefocus} key={character.id}>
              {character.name}
              <Icon name="x" className="character_search_input_token_x" color={IconColor.RED}
                onClick={(event) => {
                  onDeselectItem(character);
                  event.stopPropagation();
                }} />
            </div>)}
          {!showSelector && selectedCharacters.length > maxShowSelected &&
          <div className="character_search_input_selected_item">+{selectedCharacters.length - maxShowSelected}</div>}
        </div>
      </div>
      {showSelector && <CharacterSearchFloater unselectedCharacters={unselectedCharacters} selectedCharacters={selectedCharacters} name={name} hoverIndex={hoverIndex} onHover={onHover} onSelect={onSelectItem} onDeselect={onDeselectItem} onRefocus={onRefocus} />}
      {showSelector && <div className="character_search_container_back" onClick={() => setShowSelector(false)}>
        <Icon name="arrow-left" color={IconColor.GREY} />
      </div>}
    </div>
  );
};

export default CharacterSearch;