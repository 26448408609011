import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedPlaylist } from 'model/reducers/playlistSlice';

import PlaylistMenu from './menu';
import Playlist from './item';
import './styles.css';

function PlaylistPanel() {
  const current = useSelector(selectSelectedPlaylist);
  const [toggleOpen, setToggle] = useState(false);

  const onToggle = (value= !toggleOpen) => {
    setToggle(value);
  }

  return (
  	<div className="playlist_panel_container">
      <PlaylistMenu onTogglePlaylist={onToggle} />
      <div className={"playlist_panel_list_container " + (toggleOpen ? "" : "playlist_panel_list_container_closed")}>
        <Playlist playlist={current} onToggle={onToggle} isOpen={toggleOpen} />
      </div>
    </div>
  );
}

export default PlaylistPanel;