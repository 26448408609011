import React from 'react';
import { useSelector, useDispatch } from 'model/store';
import { actions as userActions, selectUserPreference, selectUserPermisisons } from 'model/reducers/userSlice';

import ToggleButton from 'components/widgets/toggle_button';


function UserSettings() {
  const dispatch = useDispatch();
  const { canEdit } = useSelector(selectUserPermisisons);
  const { editMode } = useSelector(selectUserPreference);

  const onToggleEditMode = (isEditMode: boolean) => {
    dispatch(userActions.changeEditMode(isEditMode));
  }

  return (
    <div className="user_settings_container">
      <h3>Settings</h3>
      <div className="user_settings_content">
        {canEdit && <div className="user_settings_content_field">
          <ToggleButton value={editMode} onSelect={onToggleEditMode} />
          <span>Edit Mode</span>
        </div>}
      </div>
    </div>
  );
}

export default UserSettings;