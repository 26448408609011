import React from 'react';

import OriginLoader from 'components/character/origin';
import MediaWatch from 'components/media/watch';
import MediaPanel from 'components/media/panel';
import Sidebar from 'components/sidebar';
import Panel from 'components/panel';
import Header from 'components/header';
import Toast from 'components/widgets/toast';
import Container from 'components/widgets/container';

function MediaPage() {

  return (
    <Container>
      <OriginLoader />
      <Header />
      <Panel
        watchPanel={<MediaWatch />}
        mainPanel={<MediaPanel />}
        sidePanel={<Sidebar />}
      />
      <Toast />
    </Container>
  );
}

export default MediaPage;