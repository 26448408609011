export enum PERMISSION {
  BASIC = 0,
  WRITE_TAG = 1,
  BASIC_WRITE = 2,
  BASIC_DELETE = 3,
  ADVANCE_WRITE = 4,
  PREMIUM = 5,
  ADMIN = 6,
}

export const hasPermission = (permissions: string, permission: PERMISSION) => {
  return permissions.charAt(permission) === '1';
}