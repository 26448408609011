import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'model/store';
import { CharacterInfo } from 'model/types/api.types';
import { selectCharacter, searchCharacter, searchCharacterMore } from 'model/reducers/characterSlice';
import { DEBOUCE_TIMER } from 'model/constants/sizes';

import useToast from 'components/widgets/toast/useToast';
import Icon, { IconColor } from 'components/widgets/icon';
import CharacterSearchItem from 'components/character/item';
import './styles.css';

type IProps = {
  unselectedCharacters: CharacterInfo[],
  selectedCharacters: CharacterInfo[],
  name: string,
  hoverIndex: number,
  onHover: (index: number) => void,
  onSelect: (character: CharacterInfo) => void,
  onDeselect: (charcter: CharacterInfo) => void,
  onRefocus: () => void,
}

function CharacterSearchFloater({ unselectedCharacters, selectedCharacters, name, hoverIndex, onHover, onSelect, onDeselect, onRefocus }: IProps) {
  const dispatch = useDispatch();
  const { origins, searchedNames } = useSelector(selectCharacter);
  const { addErrorToast } = useToast();

  useEffect(() => {
    const debounceTimeout = setTimeout( () => {
      if (!(name in searchedNames)) {
        dispatch(searchCharacter({ page: 0, name }))
          .catch(addErrorToast);
      };
    }, DEBOUCE_TIMER);
    return () => clearTimeout(debounceTimeout);
  }, [dispatch, name, searchedNames, addErrorToast]);

  const loadMoreCharacters = () => {
    dispatch(searchCharacterMore({ page: (searchedNames[name]?.page ?? 0) + 1, name }))
      .catch(addErrorToast);
    onRefocus();
  }

  return (
    <div className="character_search_float_container">
      {selectedCharacters.map( character =>
        <CharacterSearchItem key={character.id}
          onHover={() => {}}
          character={character}
          origin={origins[character.originId]}
          onSelect={onDeselect} />)}
      <div className="character_search_float_line" />
      <div className="character_search_float_container_list">
        {unselectedCharacters.map( (character, index) =>
          <CharacterSearchItem key={character.id}
            onHover={() => onHover(index)}
            isHover={index === hoverIndex}
            character={character}
            origin={origins[character.originId]}
            onSelect={onSelect} />)}
        {searchedNames[name]?.hasNext &&
          <div className="character_search_float_container_item character_search_float_container_item_more" onClick={loadMoreCharacters}>
            <Icon name="three-dots" color={IconColor.GREYER} alt="more" />
          </div>
        }
      </div>
    </div>
    );
}

export default CharacterSearchFloater;