import React, { useEffect, useRef, useCallback} from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin, CredentialResponse } from '@react-oauth/google';

type IProps = {
  onLogin: (token?: string) => void,
  isShown: boolean,
  toggleShown: (isShown: boolean) => void,
}

function AuthLogin({ onLogin, isShown, toggleShown }: IProps) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const onOutsideClicked = (event: Event) => {
      if (ref.current && !ref.current.contains(event.target as HTMLElement)) {
        toggleShown(false);
      }
    };
    document.addEventListener("mousedown", onOutsideClicked);
    return () => document.removeEventListener("mousedown", onOutsideClicked);
  }, [toggleShown]);

  const onHandleLogin = useCallback(({ credential }: CredentialResponse) => {
    onLogin(credential);
  }, [onLogin]);

  if (!isShown) return <></>;

  return (
    <div ref={ref} className="user_profile_info_container">
      <div className="user_profile_login_container">
        <GoogleOAuthProvider clientId="456583678812-s4bcfpsv71a2ih3oqmr61n81eajsmlsh.apps.googleusercontent.com">
          <GoogleLogin onSuccess={onHandleLogin} />
        </GoogleOAuthProvider>
      </div>
    </div>
  );
}

export default AuthLogin;