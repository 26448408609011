import React, { DragEvent } from 'react';

import { Media } from 'model/types/api.types';
import Icon, { IconColor } from 'components/widgets/icon';
import './styles.css';

const YOUTUBE_URL = 'https://img.youtube.com/vi/';

const onDropOver = (event: DragEvent) => {
  if (event.dataTransfer) event.dataTransfer.dropEffect = "move";
  event.preventDefault();
}

type IProps = {
  media: Media,
  isCurrent?: boolean,
  onDragStart?: (event: DragEvent<HTMLDivElement>) => void,
  onDrag?: (event: DragEvent<HTMLDivElement>) => void,
  onDrop?: (event: DragEvent<HTMLDivElement>) => void,
  onRemove?: () => void,
  onWatch?: () => void,
}

function CompactMediaItem({
  media,
  isCurrent = false,
  onDragStart = () => {},
  onDrag = () => {},
  onDrop = () => {},
  onRemove = () => {},
  onWatch = () => {},
}: IProps) {

  return (
    <div className={"compact_media_item_container"}>
      <div className="compact_media_item_image_container">
        <div className="compact_media_item_image_play" onClick={onWatch}>
          <img src="/assets/icons/play-circle.svg" alt="play" />
        </div>
        <img src={`${YOUTUBE_URL}${media.mediaSourceId}/0.jpg`} alt="thumbnail" className="compact_media_item_image_img" />
      </div>
      <div className="compact_media_item_info_container">
        <div className="compact_media_item_info_title" title={media.name}>
          <h2>{media.name}</h2>
          <div className="compact_media_item_info_song_container">
            <Icon
              name="music-note-beamed"
              color={IconColor.GREYER}
              alt="song"
              className="compact_media_item_song_icon" />
            <span className="compact_media_item_info_artist">{media.artistName}</span>
            <span className="compact_media_item_info_song">{media.songName}</span>
          </div>
        </div>
      </div>
      {!isCurrent && <div className="compact_media_remove_content" onClick={onRemove}>
        <Icon
          name="trash"
          color={IconColor.RED}
          className="compact_media_option_icon"
          alt="remove" />
      </div>}
      <div
          className="compact_media_drag_content"
          draggable
          onDragStart={onDragStart}
          onDragEnter={onDrag}
          onDragEnd={onDrop}
          onDragOver={onDropOver}>
        <Icon
          name="grip-horizontal"
          color={IconColor.WHITE}
          className="compact_media_option_icon"
          alt="drag" />
      </div>
    </div>
  );
}

export default CompactMediaItem;