import React from 'react';
import { createRoot } from 'react-dom/client';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { Provider } from 'react-redux';
import store from 'model/store';
import MediaPage from 'page/MediaPage';
import AdminPage from 'page/AdminPage';

function App() {
  return(
    <Router>
    <Switch>
      <Route path="/admin/console" component={AdminPage} />
      <Route path="" component={MediaPage} />
    </Switch>
    </Router>
  );
}

createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <App />
  </Provider>
);