import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { actions as toastActions } from 'model/reducers/toastSlice';
import { ToastType } from 'model/types/toast.types';

const DISPLAY_TIMER = 6000;

const useToast = () => {
  const dispatch = useDispatch();
  const addErrorToast = useCallback( (error: Error) => {
    dispatch(toastActions.showToast({
      type: ToastType.ERROR,
      message: error.message,
    }));
  }, [dispatch]);

  const addInfoToast = useCallback( (message: string) => {
    dispatch(toastActions.showToast({
      type: ToastType.INFO,
      message,
    }));
  }, [dispatch]);

  const popToast = (id?: number) => {
    setTimeout( () => dispatch(toastActions.closeToast(id)), DISPLAY_TIMER);
  }

  return {
    addErrorToast,
    addInfoToast,
    popToast,
  }
}

export default useToast;