import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as queueActions, selectQueue } from 'model/reducers/queueSlice';
import { actions as uiActions } from 'model/reducers/uiSlice';

import ToggleButton from 'components/widgets/toggle_button';
import MediaItem from 'components/playlist/compact_media';
import './styles.css';

function PlaylistQueuePanel() {
  const dispatch = useDispatch();
  const ref = useRef();
  const { currentMedia, list, autoPlay } = useSelector(selectQueue);
  const [ draggedItem, setDraggedItem ] = useState(null);
  const [ oldIndex, setOldIndex ] = useState();
  const [ newIndex, setNewIndex ] = useState();

  const onRemove = (mediaIndex) => {
    dispatch(queueActions.removeFromQueue({ mediaIndex }));
  }

  const onWatch = (media) => {
    dispatch(queueActions.watchMedia({ media }));
    dispatch(uiActions.changeWatchSelected(true));
  }

  const onToggleAutoplay = (value) => {
    dispatch(queueActions.toggleAutoplay(value));
  }

  const onDragStart = (event) => {
    event.dataTransfer.effectAllowed = "move";
    const element = event.currentTarget.parentElement;
    element.classList.add('playlist_media_dragged');
    setDraggedItem(element);
  }

  const onDrag = async (event) => {
    let swapItem = document.elementFromPoint(event.clientX, event.clientY).closest('.compact_media_item_container');
    if (swapItem === null || swapItem === draggedItem) return;

    const siblings = Array.from(ref.current.children);
    const oldIndex = siblings.indexOf(draggedItem);
    const newIndex = siblings.indexOf(swapItem);
    setOldIndex(oldIndex);
    setNewIndex(newIndex);
  }

  const onDrop = (event) => {
    draggedItem.classList.remove('playlist_media_dragged');
    dispatch(queueActions.reorderQueue({ oldIndex, newIndex }));
    setDraggedItem(null);
  }

  return (
    <div className="playlist_queue_list_container">
      {currentMedia !== null && <div className="playlist_queue_current">
        {<MediaItem key="0" media={currentMedia} isCurrent={true} /> }
      </div>}
      <div className="playlist_queue_list_next">
        <h3>Next</h3>
        <ToggleButton value={autoPlay} onSelect={onToggleAutoplay} title="Auto-populate when empty" />
      </div>
      {list.length > 0 && <div className="playlist_queue_next" ref={ref}>
        { list.map( (media, index) => <MediaItem
            key={`${index}_${media.id}`}
            media={media}
            onRemove={() => onRemove(index)}
            onWatch={() => onWatch(media)}
            onDragStart={onDragStart}
            onDrag={onDrag}
            onDrop={onDrop}
          />)
        }
      </div>}
      { list.length === 0 && currentMedia === null && <div className="playlist_media_list_empty">
        <div className="playlist_media_list_empty_content">Queue is empty</div>
      </div>}
    </div>
  );
}

export default PlaylistQueuePanel;