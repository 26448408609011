import React, { useState, FormEvent } from 'react';
import '../styles.css';

import { UserInfo } from 'model/types/api.types';
import { PERMISSION, hasPermission } from 'model/constants/permission';
import InputField from 'components/widgets/input_field';
import SaveCancelButtons from 'components/widgets/save_cancel_buttons';

type IProps = {
  isNew?: boolean;
  user: UserInfo;
  onSubmit: (email: string, permissions: string) => void;
}

const changePermission = (permissions: string, index: number, value: boolean) => {
  const charArray = permissions.split('');
  charArray[index] = value ? '1' : '0';
  return charArray.join('');
}

function UserItem({ isNew = false, user, onSubmit }: IProps) {
  const [email, setEmail] = useState(user.email);
  const [isDirty, setDirty] = useState(isNew && user.email.length > 0);
  const [permissions, setPermissions] = useState(user.permissions);

  const onChange = (permissions: string, index: number, value: boolean) => {
    setPermissions(changePermission(permissions, index, value));
    setDirty(true);
  }

  const onChangeEmail = (value: string) => {
    setEmail(value);
    setDirty(isNew && value?.length > 0 && value?.endsWith('@gmail.com'));
  }

  const onCancel = () => {
    setDirty(false);
    setPermissions(user.permissions);
    setEmail(user.email);
  }

  const onHandleSubmit = (event: FormEvent<HTMLFormElement>) => {
    onSubmit(email, permissions);
    setDirty(isNew);
    if (isNew) {
      setEmail('');
      setPermissions("0000000");
    }
    event.preventDefault();
  }

  return (
    <form className="admin_console_console_item" onSubmit={onHandleSubmit}>
      {!isNew && <div className="admin_console_user_console_item_col_first">{user.email}</div>}
      {isNew && <InputField value={email} onChange={onChangeEmail} iconName="plus-circle" placeholder="Add" className="admin_console_user_console_item_col_first_new" /> }
      <input type="checkbox" checked={hasPermission(permissions, PERMISSION.BASIC)} onChange={(event) => onChange(permissions, 0, event.target.checked )} className="admin_console_user_console_item_col" />
      <input type="checkbox" checked={hasPermission(permissions, PERMISSION.WRITE_TAG)} onChange={(event) => onChange(permissions, 1, event.target.checked )} className="admin_console_user_console_item_col" />
      <input type="checkbox" checked={hasPermission(permissions, PERMISSION.BASIC_WRITE)} onChange={(event) => onChange(permissions, 2, event.target.checked )} className="admin_console_user_console_item_col" />
      <input type="checkbox" checked={hasPermission(permissions, PERMISSION.BASIC_DELETE)} onChange={(event) => onChange(permissions, 3, event.target.checked )} className="admin_console_user_console_item_col" />
      <input type="checkbox" checked={hasPermission(permissions, PERMISSION.ADVANCE_WRITE)} onChange={(event) => onChange(permissions, 4, event.target.checked )} className="admin_console_user_console_item_col" />
      <input type="checkbox" checked={hasPermission(permissions, PERMISSION.PREMIUM)} onChange={(event) => onChange(permissions, 5, event.target.checked )} className="admin_console_user_console_item_col" />
      <div className={"admin_console_user_console_item_save admin_console_user_console_item_col " + (isDirty ? "" : "admin_console_user_console_item_save_hidden") }>
        <SaveCancelButtons onCancel={onCancel} />
      </div>
    </form>
  );
}

export default UserItem;