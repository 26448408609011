import React, { useState, FormEvent } from 'react';
import { useDispatch, useSelector } from 'model/store';
import { createMedia, selectMedia } from 'model/reducers/mediaSlice';

import useToast from 'components/widgets/toast/useToast';
import MediaItem from 'components/media/item';
import InputField from 'components/widgets/input_field';
import SubmitButton, { Type } from 'components/widgets/submit_button';
import './styles.css';

type IProps = {
  onDone: () => void,
}

function MediaNew({ onDone }: IProps) {
  const dispatch = useDispatch();
  const [ mediaLink, setMediaLink ] = useState('');
  const { addedMedia } = useSelector(selectMedia);
  const { addErrorToast } = useToast();

  const changeMediaSource = (mediaLink: string) => {
    setMediaLink(mediaLink);
  }

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const mediaSourceId = new URLSearchParams(new URL(mediaLink).search).get('v') ?? '';
      dispatch(createMedia({mediaSourceId}))
        .catch(addErrorToast);
    } catch {
      addErrorToast(new Error('Invalid Video URL'));
    }
  }

  return (
    <div>
      <form onSubmit={onSubmit}>
        <InputField value={mediaLink} onChange={changeMediaSource} iconName="plus-circle-fill" />
      </form>
      { addedMedia && <MediaItem media={addedMedia} canEdit showTitle /> }
      { addedMedia && <SubmitButton label="" type={Type.SAVE} className="media_new_done_button" onClick={onDone} />}
    </div>
  );
}

export default MediaNew;