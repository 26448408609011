import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UiState } from 'model/types/ui.types';
import { RootState } from 'model/store';

const initialState: UiState = {
  watchSelected: false,
  sidebarExpanded: false,
};

const uiSlice = createSlice({
  name: 'uiState',
  initialState,
  reducers: {
    changeWatchSelected(state, { payload }: PayloadAction<boolean>) {
      state.watchSelected = payload;
    },
    changeSidebarExpanded(state, { payload }: PayloadAction<boolean>) {
      state.sidebarExpanded = payload;
    },
  },
});

export const selectUi = (state: RootState): UiState => {
  return state.uiState;
}

export const { actions } = uiSlice;

export default uiSlice.reducer;