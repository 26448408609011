import React, { MouseEvent } from 'react';

type IProps = {
  label: string,
  title?: string,
  onClick: (event: MouseEvent<HTMLDivElement>) =>  void,
}

function MenuItem({ label, title = "", onClick }: IProps) {
  return (
    <div className="menu_item_container" title={title} onClick={onClick}>
      <span className="menu_item_button">{label}</span>
    </div>
  );
}

export default MenuItem;