import React from 'react';
import { CharacterInfo, CharacterOrigin } from 'model/types/api.types';
import './styles.css';

type IProps = {
  character: CharacterInfo,
  origin: CharacterOrigin,
  onHover: () => void,
  isHover?: boolean,
  onSelect: (character: CharacterInfo) => void,
}

function CharacterSearchItem({
  character,
  origin,
  isHover = false,
  onHover,
  onSelect,
}: IProps) {

  const onSelectCharacter = () => {
    onSelect(character);
  }

  return (
    <div className={"character_search_float_container_item " + (isHover ? "character_search_float_container_item_hover" : "")}
        onClick={onSelectCharacter}
        onMouseEnter={onHover}>
      <img className="character_search_float_container_item_icon" src={character.thumbnailUrl} alt="info" onError={
        ({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.src = '/assets/icons/character.svg';
        }} />
      {character.name}
      {origin.iconUrl.length > 0 && <img src={origin.iconUrl} onError={
        ({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.style.visibility = 'hidden';
        } }
        className="character_search_float_item_origin_image" alt={origin?.name} />}
      {origin.iconUrl.length === 0 && <div className="song_search_float_item_right_text character_search_float_item_right_text">{origin?.name}</div>}
    </div>
  );
}

export default CharacterSearchItem;