import React, { useState, useEffect, useCallback, MouseEvent } from 'react';
import { useSelector, useDispatch } from 'model/store';
import { selectUser, userLogin } from 'model/reducers/userSlice';
import { loadPlaylist } from 'model/reducers/playlistSlice';

import useToast from 'components/widgets/toast/useToast';
import Icon, { IconColor } from 'components/widgets/icon';
import AuthLogin from './authLogin';
import UserPanel from './userPanel';

import './styles.css';

const EXPIRY_TIME = 3600000;

function User() {
  const dispatch = useDispatch();
  const { isLoggedIn, userInfo } = useSelector(selectUser);
  const [showInfo, setShowInfo] = useState(false);
  const { addErrorToast } = useToast();

  const onLogin = useCallback((token?: string) => {
    if (token === null || token === undefined) return;
    dispatch(userLogin({ token }))
      .then(() => {
        localStorage.setItem('user_cred', JSON.stringify({
          credential: token,
          expiry: Date.now() + EXPIRY_TIME,
        }));
        dispatch(loadPlaylist()).catch(addErrorToast);
      })
      .catch((error) => {
        localStorage.removeItem('user_cred');
        addErrorToast(error);
      });
    onClose();
  }, [dispatch, addErrorToast]);

  useEffect(() => {
    const userCredJson = localStorage.getItem('user_cred');
    if (userCredJson) {
      const userCred = JSON.parse(userCredJson);
      if (userCred.expiry > Date.now()) onLogin(userCred.credential);
      else localStorage.removeItem('user_cred');
    }
  }, [onLogin]);

  const toggleShowInfo = (event: MouseEvent) => {
    setShowInfo(!showInfo);
    event.stopPropagation();
  }

  const onClose = () => {
    setShowInfo(false);
  }

  return (
    <>
      <div className="user_profile_container" onClick={toggleShowInfo}>
        {!isLoggedIn && <Icon name="not-logged-in" color={IconColor.GREY} className="user_profile_container_icon user_profile_container_no_user" alt="Profile" />}
        {isLoggedIn && <img src={userInfo.avatarUrl} referrerPolicy="no-referrer" className="user_profile_container_icon user_profile_container_user" alt="Profile" title={userInfo.email}  />} 
      </div>
      <AuthLogin onLogin={onLogin} isShown={showInfo && !isLoggedIn} toggleShown={setShowInfo} />
      <UserPanel isShown={showInfo && isLoggedIn} toggleShown={setShowInfo} />
    </>
  );
}

export default User;