import React, { useState, useEffect, FormEvent } from 'react';

import { ID, Token, CharacterInfo } from 'model/types/api.types';
import { get_listCharacterOrigin, get_searchCharacter, post_editCharacter, post_newCharacter } from 'model/apis/apis';
import InputField from 'components/widgets/input_field';
import CharacterItem from './characterItem';
import '../styles.css';

type IProps = {
  authToken: Token,
}

function CharacterConsole({ authToken }: IProps) {
  const [searchName, setSearchName] = useState<string>('');
  const [origins, setOrigins] = useState({});
  const [characters, setCharacters] = useState<CharacterInfo[]>([]);

  useEffect(() => {
    get_listCharacterOrigin(0).then( response => 
      setOrigins((response.items ?? []).reduce( (map: any, origin: any) => {
        map[origin.id] = origin;
          return map;
        }, {})));
  }, [setOrigins]);

  const onSearchName = (value: string) => {
    setSearchName(value);
  }

  const onSubmitSearch = (event: FormEvent<HTMLFormElement>) => {
    get_searchCharacter(0, searchName).then( response => setCharacters(response?.items ?? []));
    event.preventDefault();
    return false;
  }

  const onSubmitEdit = (id: ID, name: string, thumbnailUrl: string, originId: ID) => {
    post_editCharacter(authToken, { id, name, thumbnailUrl, originId })
      .then( (characterInfo) => {
        const index = characters.findIndex( character => character.id === id);
        const updated = [...characters];
        updated[index] = characterInfo;
        setCharacters(updated);
      });
  }

  const onSubmitNew = (id: ID, name: string, thumbnailUrl: string, originId: ID) => {
    post_newCharacter(authToken, { id, name, thumbnailUrl, originId })
      .then( (characterInfo) => {
        setCharacters([...characters, characterInfo]);
      })
  }
 
  return (
    <div className="admin_console_console">
      <form onSubmit={onSubmitSearch}>
        <InputField value={searchName} onChange={onSearchName} iconName="people" placeholder="Search" />
      </form>
      <div className="admin_console_console_list">
        { characters.map( (character, index) => <CharacterItem key={index} origins={origins} character={character} onSubmit={onSubmitEdit} /> ) }
        <CharacterItem isNew origins={origins} character={{ id: 0, name: '', thumbnailUrl: '', originId: 1 }} onSubmit={onSubmitNew} />
      </div>
    </div>
  );
}

export default CharacterConsole;