import React from 'react';
import { ID, CharacterInfo, SongInfo } from 'model/types/api.types';
import CharacterSearchItem from 'components/character/item';
import SongSearchItem from './songSearchItem';
import './styles.css';

export enum ItemType {
  CHARACTER = 0,
  SONG = 1,
}

export type SearchItem = {
  type: ItemType;
  name: string;
  item: CharacterInfo | SongInfo;
}

type IProps = {
  isShown: boolean,
  origins: any,
  searchItems: SearchItem[],
  selectedCharacterIds: ID[],
  selectedSongIds: ID[],
  hoverIndex: number,
  onSelectItem: (item: SearchItem) => void,
  onHasFocus?: (value: boolean) => void,
  onHover: (index: number) => void,
}

function SearchDropdown({
  origins,
  searchItems,
  selectedCharacterIds,
  selectedSongIds,
  isShown,
  hoverIndex,
  onSelectItem,
  onHover,
  onHasFocus = () => {},
}: IProps) {
  if (!isShown) return <></>;

  const onSelectCharacter = (item: CharacterInfo) => {
    onSelectItem({ type: ItemType.CHARACTER, name: item.name, item });
    onHasFocus(true);
  }

  const onSelectSong = (item: SongInfo) => {
    onSelectItem({ type: ItemType.SONG, name: item.title, item });
    onHasFocus(true);
  }

  return(
    <div className="searchbar_dropdown">
      { searchItems.map( ( { type, item }, index) => {
          switch(type) {
            case ItemType.CHARACTER:
              const charItem = item as CharacterInfo;
              return (
                <CharacterSearchItem
                  key={index}
                  origin={origins[charItem.originId]}
                  character={charItem}
                  isHover={hoverIndex === index}
                  onSelect={onSelectCharacter}
                  onHover={() => onHover(index)}
                 />
               );
            case ItemType.SONG: 
              const songItem = item as SongInfo;
              return (
                <SongSearchItem
                  key={index}
                  song={songItem}
                  isHover={hoverIndex === index}
                  onSelect={onSelectSong}
                  onHover={() => onHover(index)}
                />
              );
            default: return <div />
          }
      }) }
    </div>
  );
}

export default SearchDropdown;