import React, { useState, useCallback, useRef, useEffect } from 'react';

import Icon, { IconColor } from 'components/widgets/icon';
import Info from 'json/info.json';
import './styles.css';

type IProps = {
  onOpen: (isOpen: boolean) => void,
  children?: (JSX.Element | boolean)[],
}

function Menubar({ onOpen, children }: IProps) {
  const ref = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const [showExpand, setShowExpand] = useState(false);

  const onMenuOpen = useCallback((isOpen: boolean) => {
    onOpen(!isOpen);
    setShowExpand(isOpen);
    if (isOpen) {
      menuRef.current?.classList.remove('menubar_icon_container_shown');
    } else {
      menuRef.current?.classList.add('menubar_icon_container_shown');
    }
  }, [onOpen]);

  useEffect(() => {
    const onOutsideClicked = (event: Event) => {
      if (menuRef.current && !menuRef.current.contains(event.target as HTMLElement) && (!ref?.current?.contains(event.target as HTMLElement)) ) {
        onMenuOpen(true);
      }
    };
    document.addEventListener("mousedown", onOutsideClicked);
    return () => document.removeEventListener("mousedown", onOutsideClicked);
  }, [onMenuOpen]);

  return (
    <>
      <div className="menubar_icon_container" ref={ref} onClick={(event) => onMenuOpen(!showExpand)}>
        <img src="/favicon.svg" className="top_icon" alt="menu" />
      </div>
      <div className="menubar_content_container" ref={menuRef}>
        <div className="menubar_content">
          {children}
        </div>
        <div className="menubar_footer">
          <div className="menubar_footer_item">
            <Icon name="discord" color={IconColor.DISCORD_PURPLE} />
            <a href={Info.discord}>Join on discord</a>
          </div>
          <div className="menubar_footer_item menubar_version">
            <span />
            <span>v{Info.version}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Menubar;