import React, { MouseEvent } from 'react';
import './styles.css';

type IProps = {
    children: JSX.Element,
    isShown: boolean,
    onHide?:((event: MouseEvent<HTMLDivElement>) => void),
    className?: string,
}

function Modal({ children, isShown, onHide = undefined, className = "" }: IProps) {
    const noCancel = onHide === undefined;
    return isShown ?
        <div className="modal_container">
            <div className="modal_background" onClick={onHide} />
            <div className={"modal_dialog modal_dialog_float_out " + className}>
                <div className="modal_header">
                    {!noCancel && <img src="/assets/icons/x.svg" alt="close" onClick={onHide} />}
                </div>
                <div className="modal_content">
                    {children}
                </div>
            </div>
        </div> : null
}

export default Modal;