import React, { useState, FormEvent } from 'react';
import { useDispatch } from 'model/store';

import { newPlayList } from 'model/reducers/playlistSlice';
import useToast from 'components/widgets/toast/useToast';

import Icon, { IconColor } from 'components/widgets/icon';
import InputField from 'components/widgets/input_field';
import SaveCancelButtons from 'components/widgets/save_cancel_buttons';
import './styles.css';

type IProps = {
  onShowEdit: (isEdit: boolean) => void,
}

function PlaylistNew({ onShowEdit }: IProps) {
  const dispatch = useDispatch();
  const [ showEdit, setEdit] = useState(false);
  const [ name, setPlaylistName ] = useState('');
  const { addErrorToast } = useToast();

  const onShowEditOpen = () => {
    setEdit(true);
    onShowEdit(true);
  }

  const onCancelEdit = (event: FormEvent<HTMLInputElement> | FormEvent<HTMLFormElement>) => {
    setEdit(false);
    setPlaylistName('');
    onShowEdit(false);
    event.stopPropagation();
  }

  const onNewPlaylist = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(newPlayList({ name })).then( () => {
      onCancelEdit(event);
    }).catch(addErrorToast);
  }

  return (
    <div className={"playlist_menu_add_container" + (showEdit ? " playlist_menu_add_container_expanded" : "")}>
      {!showEdit && <Icon name="plus-circle-fill" color={IconColor.GREEN} alt="New Playlist" onClick={onShowEditOpen} title="New Playlist" />}
      {showEdit && <form className="playlist_menu_add_input_container" onSubmit={onNewPlaylist}>
        <InputField value={name} onChange={setPlaylistName} iconName="plus-circle-fill" />
        <SaveCancelButtons onCancel={onCancelEdit} />
      </form>}
    </div>
  );
}

export default PlaylistNew;