import React, { FormEvent } from 'react';
import 'components/widgets/icon/icon_colors.css';
import './styles.css';

export enum Type {
  SAVE = 'save',
  SAVE_BUTTON = 'save_button',
  BUTTON = 'button',
  CANCEL = 'cancel',
}

type IProps = {
  type: Type,
  label?: string,
  className?: string,
  onClick?: (event: FormEvent<HTMLInputElement>) => void;
}

function SubmitButton({ type, label = '', className = '', onClick = () => {} }: IProps) {
  let inputClass;
  let inputType;
  switch(type) {
    case Type.SAVE:
      inputClass = 'submit_button submit_button_check icon_color_green';
      inputType = "submit";
      break;
    case Type.SAVE_BUTTON:
      inputClass = 'submit_button submit_button_check icon_color_green';
      inputType = "button";
      break;
    case Type.BUTTON:
      inputClass = "submit_button icon_color_green"
      inputType =  "button";
      break;
    case Type.CANCEL:
      inputClass = 'submit_button icon_color_red submit_button_x';
      inputType = "button";
      break;
    default:
      inputClass = "submit_button";
  };
  return <input className={className + ' ' + inputClass} value={label} type={inputType} onClick={onClick} />;
}

export default SubmitButton;