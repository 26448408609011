import { configureStore } from '@reduxjs/toolkit';
import { useDispatch as useReduxDispatch, useSelector as useReduxSelector } from 'react-redux';

import uiReducer from './reducers/uiSlice';
import userReducer from './reducers/userSlice';
import mediaReducer from './reducers/mediaSlice';
import queueReducer from './reducers/queueSlice';
import characterReducer from './reducers/characterSlice';
import playlistReducer from './reducers/playlistSlice';
import toastReducer from './reducers/toastSlice';

import { UserState } from './types/user.types';
import { CharacterState } from './types/character.types';
import { MediaState } from './types/media.types';
import { PlaylistState } from './types/playlist.types';
import { QueueState } from './types/queue.types';
import { UiState } from './types/ui.types';
import { ToastState } from './types/toast.types';

export type RootState = {
  userState: UserState;
  characterState: CharacterState;
  mediaState: MediaState;
  playlistState: PlaylistState;
  queueState: QueueState;
  uiState: UiState;
  toastState: ToastState;
}

const store = configureStore({
  reducer: {
    uiState: uiReducer,
    userState: userReducer,
    mediaState: mediaReducer,
    queueState: queueReducer,
    characterState: characterReducer,
    playlistState: playlistReducer,
    toastState: toastReducer,
  },
});

type StoreType = typeof store.dispatch;
export const useDispatch = () => useReduxDispatch<StoreType>();
export const useSelector = useReduxSelector;

export default store;