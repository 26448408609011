import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as toastActions, selectToast } from 'model/reducers/toastSlice';

import ToastItem from './ToastItem';
import './styles.css';

function Toasts() {
  const dispatch = useDispatch();
  const { toasts } = useSelector(selectToast);

  const onClose = (id?: number) => {
    dispatch(toastActions.closeToast(id));
  }

  return (
    <>
      {<div className="toasts_container">
        { toasts.map( toast => <ToastItem
            key={toast.id}
            toast={toast}
            onClose={onClose}
          />).reverse()}
      </div>}
    </>
  );
}

export default Toasts;