import React, { useState, useEffect } from 'react';
import { ID, Token, CharacterOrigin } from 'model/types/api.types';
import { get_listCharacterOrigin, post_editCharacterOrigin, post_newCharacterOrigin } from 'model/apis/apis';
import OriginItem from './originItem';
import '../styles.css';

type IProps = {
  authToken: Token
}

function OriginConsole({ authToken }: IProps) {
  const [origins, setOrigins] = useState<CharacterOrigin[]>([]);

  useEffect(() => {
    get_listCharacterOrigin(0).then( response => setOrigins(response?.items ?? []));
  }, [setOrigins]);

  const onSubmitEdit = (id: ID, name: string, iconUrl: string) => {
    post_editCharacterOrigin(authToken, {
      id, name, iconUrl
    }).then( (originInfo) => {
      const index = origins.findIndex( origin => origin.id === id );
      const updated = [...origins];
      updated[index] = originInfo;
      setOrigins(updated);
    });
  }

  const onSubmitNew = (id: ID, name: string, iconUrl: string) => {
    post_newCharacterOrigin(authToken, {
      id, name, iconUrl
    }).then( (originInfo) => setOrigins([...origins, originInfo]));
  }

  return (
    <div className="admin_console_console">
      {origins.map( (origin, index) => <OriginItem key={index} origin={origin} onSubmit={onSubmitEdit} /> )}
      <OriginItem isNew origin={{id: 0, name: '', iconUrl: ''}} onSubmit={onSubmitNew} />
    </div>
  );
}

export default OriginConsole;