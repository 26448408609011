import React from 'react';
import { ID, CharacterOrigin } from 'model/types/api.types';

type IProps = {
  origins: any;
  value: ID;
  onChange: (value: ID) => void;
}

function OriginDropdown({ origins, value, onChange }: IProps) {
  const options = Object.values(origins) as CharacterOrigin[];

  return(
    <select value={value} onChange={ (event) => onChange(parseInt(event.target.value))}>
      { options.map( option => <option key={option.id} value={option.id}>{option.name}</option> ) }
    </select>
  );
}

export default OriginDropdown;