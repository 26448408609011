import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'model/store';
import { Media } from 'model/types/api.types';
import { selectFavourites, loadFavourites, removeFromFavourites } from 'model/reducers/playlistSlice';

import useToast from 'components/widgets/toast/useToast';
import MediaItem from 'components/playlist/compact_media';
import './styles.css';

function PlaylistFavouritePanel() {
  const dispatch = useDispatch();
  const { media } = useSelector(selectFavourites);
  const { addErrorToast } = useToast();

  useEffect(() => {
    dispatch(loadFavourites())
      .catch(addErrorToast);
  }, [dispatch, addErrorToast]);

  const onRemove = (media: Media) => {
    dispatch(removeFromFavourites({ mediaId: media.id }))
      .catch(addErrorToast);
  }

  return (
    <div className="playlist_fav_list_container">
      { media.map( (item, index) => <MediaItem
          key={`${index}_${item.id}`}
          media={item}
          onRemove={() => onRemove(item)}
      />) }
      { media.length === 0 && <div className="playlist_media_list_empty">
        <div className="playlist_media_list_empty_content">Favourites is empty</div>
      </div> }
    </div>
  )
}

export default PlaylistFavouritePanel;