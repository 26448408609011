import React, {useState} from 'react';
import { ID, Token, CharacterInfo } from 'model/types/api.types';
import { post_mediaScrape } from 'model/apis/apis';

import InputField from 'components/widgets/input_field';
import SubmitButton, { Type } from 'components/widgets/submit_button';

type IProps = {
  authToken: Token,
}

function MediaScrapperConsole({ authToken }: IProps) {
  const [channelId, setChannelId] = useState('');

  const onHandleSubmit = async () => {
    await post_mediaScrape(authToken, channelId);
  }

  return (
    <form onSubmit={onHandleSubmit}>
      <InputField value={channelId} onChange={setChannelId} iconName="info-circle" placeholder="Channel ID" />
      <SubmitButton className="" type={Type.SAVE} />
    </form>
  );
}

export default MediaScrapperConsole;