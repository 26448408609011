import React, { ChangeEvent } from 'react';
import './styles.css';

type IProps = {
  value: boolean,
  onSelect: (value: boolean) => void,
  title?: string,
}

function ToggleButton({ value, onSelect, title = "" }: IProps) {
  return (
  <div className="toggle_button_container">
    <label className="toggle_button_switch" title={title}>
      <input type="checkbox"
        checked={value}
        onChange={ (event: ChangeEvent<HTMLInputElement>) => {
          onSelect(event.target.checked)
        }} />
      <span className="toggle_button_slider"></span>
    </label>
  </div>
  );
}

export default ToggleButton;