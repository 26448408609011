import React, { useState, MouseEvent } from 'react';
import { useDispatch, useSelector } from 'model/store';
import { Media } from 'model/types/api.types';
import { deleteMedia } from 'model/reducers/mediaSlice';
import { actions as queueActions } from 'model/reducers/queueSlice';
import { actions as uiActions } from 'model/reducers/uiSlice';
import { selectUser } from 'model/reducers/userSlice';
import { addToFavourite } from 'model/reducers/playlistSlice';

import useToast from 'components/widgets/toast/useToast';
import Icon, { IconColor } from 'components/widgets/icon';
import OptionMenu, { OptionItem } from 'components/widgets/option_menu';
import PlaylistSelectModal from 'components/playlist/menu/playlistSelectModal';
import SongInfo from './SongInfo';
import CharactersInfo from './CharactersInfo';
import TagInfo from './TagInfo';
import './styles.css';

const YOUTUBE_URL = 'https://img.youtube.com/vi/';

type IProps = {
  media: Media,
  canEdit: boolean,
  showTitle: boolean, 
}

function MediaItem({ media, canEdit, showTitle }: IProps) {
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector(selectUser);
  const [showAddPlayist, setShowAddPlaylist] = useState(false);
  const { addInfoToast, addErrorToast } = useToast();

  const onRemove = (event: MouseEvent<HTMLDivElement>) => {
    if (event.shiftKey) {
      dispatch(deleteMedia({ mediaSourceId: media.mediaSourceId }))
        .catch(addErrorToast);
    }
  }

  const onAddToPlaylist = () => {
    setShowAddPlaylist(true);
  }

  const onHidePlaylist = () => {
    setShowAddPlaylist(false);
  }

  const onAddToQueue = () => {
    dispatch(queueActions.addToQueue({ media }));
    addInfoToast("Added to Queue");
  }

  const onAddToFavourite = () => {
    dispatch(addToFavourite({ media }))
      .then(() => addInfoToast("Added to Favourites"))
      .catch(addErrorToast);
  }

  const onWatch = () => {
    dispatch(queueActions.watchMedia({ media }));
    dispatch(uiActions.changeWatchSelected(true));
  }

  const options: OptionItem[] = [{
      icon: <Icon name="list-ul" alt="add queue" color={IconColor.PURPLE} className="media_item_info_option_icon" />,
      title: "Add to Queue",
      action: onAddToQueue,
    }, {
      icon: <Icon name="music-note-list" alt="add playlist" color={IconColor.AZURE} className="media_item_info_option_icon" />,
      title: "Add to Playlist",
      action: onAddToPlaylist,
  }];
  if (isLoggedIn) {
    options.push({
      icon: <Icon name="star-fill" alt="add favourite" color={IconColor.YELLOW} className="media_item_info_option_icon" />,
      title: "Add to Favourite",
      action: onAddToFavourite,
    });
  }
  if (canEdit) {
    options.push({
      icon: <Icon name="trash" alt="remove" color={IconColor.RED} className="media_item_info_option_icon" />,
      title: "Remove (Hold Shift)",
      action: onRemove,
    });
  }

  return (
    <div className="media_item_container">
      <div className="media_item_image_container">
        <div className="media_item_image_play" onClick={onWatch}>
          <Icon name="play-circle" color={IconColor.BLACK} alt="play" />
        </div>
        <img src={`${YOUTUBE_URL}${media.mediaSourceId}/0.jpg`}
          alt="thumbnail" className="media_item_image_img" />
      </div>
      <div className="media_item_info_container">
        <div className="media_item_info_title" title={media.name}>
          <h2>{showTitle && media.name}</h2>
        </div>
        <div className="media_item_info">
          <SongInfo media={media} canEdit={canEdit} />
          <CharactersInfo media={media} canEdit={canEdit} />
          <div className="media_item_info_options">
            <OptionMenu options={options} />
          </div>
        </div>
        <TagInfo media={media} canEdit={canEdit} />
      </div>
      <PlaylistSelectModal isShown={showAddPlayist} onHide={onHidePlaylist} media={media} />
    </div>
  );
}

export default MediaItem;