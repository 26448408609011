import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'model/store';
import { actions as uiActions } from 'model/reducers/uiSlice';
import { selectUser } from 'model/reducers/userSlice';

import Icon, { IconColor } from 'components/widgets/icon';
import SidebarItem from './SidebarItem';
import PlaylistPanel from 'components/playlist';
import PlaylistQueuePanel from 'components/queue';
import PlaylistFavouritePanel from 'components/favourites';
import MediaNewModal from 'components/media/new';
import './styles.css';

const MenuComponent = {
  NONE: {
    id: 'NONE',
    component: <div />,
  },
  QUEUE: {
    id: 'QUEUE',
    component: <PlaylistQueuePanel />,
  },
  PLAYLIST: {
    id: 'PLAYLIST',
    component: <PlaylistPanel />,
  },
  FAVOURITES: {
    id: 'FAVOURITES',
    component: <PlaylistFavouritePanel />,
  },
  NEW: {
    id: 'NEW',
    component: <div />,
  },
}

const defaultMenuItems = [{
    value: MenuComponent.QUEUE.id,
    icon: <Icon name="list-ul" color={IconColor.PURPLE} alt="queue" title="Queue" className="sidebar_item_icon" />,
  }, {
    value: MenuComponent.PLAYLIST.id,
    icon: <Icon name="music-note-list" color={IconColor.AZURE} alt="playlist" title="Playlist" className="sidebar_item_icon" />,
  },
];

let resizeTimeout: any;

function Sidebar() {
  const dispatch = useDispatch();
  const ref = useRef<HTMLDivElement>(null);
  const { isLoggedIn, preferences } = useSelector(selectUser);

  const [menuItems, setMenuItems] = useState(defaultMenuItems);
  const [selectedTab, setSelectedTab] = useState(MenuComponent.NONE);
  const [showNewMediaModal, setShowNewMediaModal] = useState(false);

  useEffect( () => {
    const handleResize = () => {
      clearTimeout(resizeTimeout);
      ref.current?.classList.add('no_transition');
      resizeTimeout = setTimeout( () => {
        ref.current?.classList.remove('no_transition');
      }, 100);
    }

    let menuItems = [ ...defaultMenuItems ];
    if (isLoggedIn) {
      menuItems.push({
        value: MenuComponent.FAVOURITES.id,
        icon: <Icon name="star-fill" color={IconColor.YELLOW} alt="favourites" title="Favourites" className="sidebar_item_icon" />,
      });
    }
    if (isLoggedIn && preferences.editMode) {
      menuItems.push({
        value: MenuComponent.NEW.id,
        icon: <Icon name="plus-circle-fill" color={IconColor.GREEN} alt="favourites" title="Favourites" className="sidebar_item_icon" />,
      });
    }
    setMenuItems(menuItems);

    return window.addEventListener('resize', handleResize);
  }, [isLoggedIn, preferences]);

  const onSelectItem = (value: string) => {
    if (value === selectedTab.id) {
      setSelectedTab(MenuComponent.NONE);
      dispatch(uiActions.changeSidebarExpanded(false));
    } else if (value === MenuComponent.NEW.id) {
      setShowNewMediaModal(true);
    } else {
      // @ts-ignore
      setSelectedTab(MenuComponent[value]);
      dispatch(uiActions.changeSidebarExpanded(true));
    }
  }

  const closeSidebar = () => {
    setSelectedTab(MenuComponent.NONE);
    dispatch(uiActions.changeSidebarExpanded(false));
  }

  return (
    <div className="sidebar_container">
      <div className="sidebar_items">
        {menuItems.map( item =>
          <SidebarItem
            key={item.value}
            icon={item.icon}
            value={item.value}
            isSelected={item.value === selectedTab.id}
            onSelect={onSelectItem}
          />)}
        <div className="sidebar_hide" onClick={closeSidebar}>
          <Icon name="chevron-compact-right" color={IconColor.GREY} alt="hide" className={"sidebar_hide_icon sidebar_hide_icon_horizontal " + (selectedTab.id === MenuComponent.NONE.id ? "sidebar_button_hidden" : "")} />
          <Icon name="chevron-compact-down" color={IconColor.GREY} alt="hide" className={"sidebar_hide_icon sidebar_hide_icon_vertical " + (selectedTab.id === MenuComponent.NONE.id ? "sidebar_button_hidden" : "")} />
        </div>
      </div>
      <div ref={ref} className={"sidebar_content " + (selectedTab.id === MenuComponent.NONE.id ? "" : "sidebar_content_open")} >
        {selectedTab.component}
      </div>
      <MediaNewModal isShown={showNewMediaModal} toggleShown={setShowNewMediaModal} />
    </div>
  );
}

export default Sidebar;