import React from 'react';

import Container from 'components/widgets/container';
import Toast from 'components/widgets/toast';
import AdminConsole from 'components/admin';

function AdminPage() {
  return (
    <Container>
      <AdminConsole />
      <Toast />
    </Container>
  );
}

export default AdminPage;