import React, { useState, useEffect, useRef, MouseEvent, KeyboardEvent, ChangeEvent } from 'react';

import Icon, { IconColor } from 'components/widgets/icon';
import './styles.css';

export enum Size {
  NORMAL = 'normal',
  LARGE = 'large',
}

type IProps = {
  value: string | number,
  onChange: (value: string) => void,
  onHasFocus?: (value: boolean) => void,
  onKeyDown?: (event: KeyboardEvent) => void,
  iconName: string,
  placeholder?: string,
  size?: Size,
  className?: string,
  autoFocus?: boolean,
}

function InputField({ value, onChange, onHasFocus = () => {}, onKeyDown = () => {}, iconName, placeholder = '', size = Size.NORMAL, className = "", autoFocus = false }: IProps) {
  const ref = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [hasFocus, setHasFocus] = useState(false);
  useEffect(() => {
    const onOutsideClicked = (event: Event) => {
      if (ref.current && !ref.current.contains(event.target as HTMLElement) ) {
        setHasFocus(false);
        onHasFocus(false);
      }
    };
    document.addEventListener("mousedown", onOutsideClicked);
    return () => document.removeEventListener("mousedown", onOutsideClicked);
  });

  let sizeClass = "";
  switch(size) {
    case Size.NORMAL: break;
    case Size.LARGE: sizeClass = "input_field_input_text_large"; break;
    default: break;
  }

  const onClickItem = (event: MouseEvent) => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    event.stopPropagation();
  }

  const onHandleFocus = () => {
    setHasFocus(true);
    onHasFocus(true);
  }

  const onHandleChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    onChange(event.target.value);
  }

  return (
    <div className={"input_field_input_container " + className} ref={ref} onFocus={onHandleFocus}>
      <Icon name={iconName} alt="" color={IconColor.GREYER} className="input_field_icon" onClick={onClickItem} />
      <input className={"input_field_input_text " + sizeClass}
        autoFocus={autoFocus}
        ref={inputRef}
        placeholder={hasFocus ? placeholder : ''}
        type="text"
        value={value}
        onChange={onHandleChange}
        onKeyDown={onKeyDown} />
    </div>
  );
}

export default InputField;