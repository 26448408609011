import React from 'react';
import { useSelector } from 'react-redux';

import { selectUserPreference } from 'model/reducers/userSlice';

import MediaList from 'components/media/list';

function MediaPanel() {
  const preferences = useSelector(selectUserPreference);

  return (
    <MediaList preferences={preferences} />
  );
}

export default MediaPanel;