import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import {
  get_playlists,
  post_newPlaylist,
  post_renamePlaylist,
  post_savePlaylist,
  delete_playlist,
  get_favourites,
  post_favourite,
  delete_favourites,
} from '../apis/apis';
import { getAuthToken } from 'model/utils/useUtils';
import { ID, PageItems, Playlist, MediaBaseRequest, NameRequest } from 'model/types/api.types';
import { PlaylistID, PlaylistState, FavouriteMedia, PlaylistMediaRequest,
 PlaylistRequest, PlaylistMediaIdRequest, PlaylistExportRequest, ReorderIndices,
 PlaylistEntry, PlaylistEntryRemove, PlaylistEntryRename, PlaylistEntryReorder, PlaylistRemove, PlaylistSelected,
 FavouriteEntry, FavouriteEntryRemove,
} from 'model/types/playlist.types';
import { RootState } from 'model/store';


const initialState: PlaylistState = {
  isLoading: false,
  current: null,
  mediaIndex: 0,
  favourites: { media: [], name: 'Favourites', id: null },
  list: [],
  selectedPlaylist: null,
}

const getState = (thunkAPI: any): PlaylistState => {
  return (thunkAPI.getState() as RootState).playlistState;
}

const findPlaylistIndex = (playlists: Playlist[], id: PlaylistID) => {
  return playlists.findIndex( playlist => playlist.id === id);
}

export const loadPlaylist = createAsyncThunk('/media/playlist/load', async (_value, thunkAPI) => {
  const playlists = await get_playlists(getAuthToken(thunkAPI));
  return playlists;
});

export const loadFavourites = createAsyncThunk('/media/favourites/list', async(_value, thunkAPI) => {
  return await get_favourites(getAuthToken(thunkAPI));
});

export const newPlayList = createAsyncThunk('/media/playlist/new', async ({ name }: NameRequest, thunkAPI) => {
  const authToken = getAuthToken(thunkAPI);
  const { list } = getState(thunkAPI);
  if (name?.length === 0) throw new Error('Empty playlist name');
  else if (list.some( item => item.name === name )) throw new Error('Duplicate playlist name');
  if (authToken && authToken.length) {
    return await post_newPlaylist(authToken, name);
  } else {
    const size = (list || []).length;
    return { name, media: [], id: 'unsaved_' + size };
  }
});

export const addToPlaylist = createAsyncThunk('/media/playlist/media/add', async ({ playlistId, media }: PlaylistMediaRequest, thunkAPI) => {
  const { list } = getState(thunkAPI);
  const index = list.findIndex( playlist => playlist.id === playlistId);
  if (list[index].media.some( existingItem => existingItem.mediaSourceId === media.mediaSourceId)) {
    return null;
  }
  return { index, media };
});

export const removeFromPlaylist = createAsyncThunk('/media/playlist/media/remove', async({ playlistId, mediaId }: PlaylistMediaIdRequest, thunkAPI ) => {
  const { list } = getState(thunkAPI);
  const index = list.findIndex( playlist => playlist.id === playlistId);
  let mediaIndex = -1;
  if (index >= 0) {
    mediaIndex = list[index].media.findIndex( item => item.id === mediaId);
  }
  return { index, mediaIndex };
});

export const removeFromFavourites = createAsyncThunk('/media/favourites/media/remove', async({ mediaId }: MediaBaseRequest, thunkAPI) => {
  const { favourites } = getState(thunkAPI);
  if (!await delete_favourites(getAuthToken(thunkAPI), mediaId)) {
    throw new Error("Unable to delete favourite");
  }
  const mediaIndex = favourites.media.findIndex( item => item.id === mediaId);
  return { mediaIndex };
});

export const choosePlaylist = createAsyncThunk('/media/playlist/select', async ({ id }: PlaylistRequest, thunkAPI) => {
  const { list } = getState(thunkAPI);
  const index = findPlaylistIndex(list, id);
  return { id, index }
});

export const reorderPlaylist = createAsyncThunk('/media/playlist/reorder', async ({ oldIndex, newIndex }: ReorderIndices, thunkAPI) => {
  const { list, current } = getState(thunkAPI);
  const index = findPlaylistIndex(list, current);
  const medias = [...list[index].media];
  const item = medias.splice(oldIndex, 1)[0];
  medias.splice(newIndex, 0, item);
  return { index, medias };
});

export const exportPlaylist = createAsyncThunk('/media/playlist/save', async ({ playlist }: PlaylistExportRequest, thunkAPI) => {
  await post_savePlaylist(getAuthToken(thunkAPI), playlist);
});

export const deletePlaylist = createAsyncThunk('/media/playlist/remove', async ({ id }: PlaylistRequest, thunkAPI) => {
  const { list } = getState(thunkAPI);
  const index = findPlaylistIndex(list, id);
  if (!isNaN(id as any)) {
    await delete_playlist(getAuthToken(thunkAPI), id as ID);
  }
  return { index };
});

export const renamePlaylist = createAsyncThunk('/media/playlist/rename', async ({ id, name = "" }: PlaylistRequest, thunkAPI) => {
  const authToken = getAuthToken(thunkAPI);
  const { list } = getState(thunkAPI);
  if (!isNaN(id as number)) await post_renamePlaylist(authToken, parseInt(id as string), name);
  const index = list.findIndex( playlist => playlist.id === id);
  return { index, name };
});

export const addToFavourite = createAsyncThunk('/media/favourites/add', async({ media }: FavouriteMedia, thunkAPI) => {
  await post_favourite(getAuthToken(thunkAPI), media.id);
  return { media };
});

const loadPending = (state: PlaylistState) => {
  state.isLoading = true;
}

const loadRejected = (state: PlaylistState, { error }: any) => {
  state.isLoading = false;
  throw new Error(error.message);
}

export const playlistSlice = createSlice({
  name: 'playlistState',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(newPlayList.pending, loadPending).addCase(newPlayList.rejected, loadRejected)
      .addCase(loadPlaylist.pending, loadPending).addCase(loadPlaylist.rejected, loadRejected)
      .addCase(reorderPlaylist.pending, loadPending).addCase(reorderPlaylist.rejected, loadRejected)
      .addCase(deletePlaylist.pending, loadPending).addCase(deletePlaylist.rejected, loadRejected)
      .addCase(addToFavourite.pending, loadPending).addCase(addToFavourite.rejected, loadRejected)
      .addCase(exportPlaylist.pending, loadPending).addCase(exportPlaylist.rejected, loadRejected)
      .addCase(removeFromFavourites.pending, loadPending).addCase(removeFromFavourites.rejected, loadRejected)
      .addCase(loadFavourites.pending, loadPending).addCase(loadFavourites.rejected, loadRejected)
      .addCase(addToPlaylist.fulfilled, (state: PlaylistState, { payload }: PayloadAction<PlaylistEntry>) => {
        if (payload && payload.index >= 0) state.list[payload.index].media.push(payload?.media);
      })
      .addCase(removeFromPlaylist.fulfilled, (state: PlaylistState, { payload }: PayloadAction<PlaylistEntryRemove>) => {
        if (payload && payload.index >= 0 && payload.mediaIndex >= 0) state.list[payload.index].media.splice(payload.mediaIndex, 1);
      })
      .addCase(renamePlaylist.fulfilled, (state: PlaylistState, { payload }: PayloadAction<PlaylistEntryRename>) => {
        if (payload) state.list[payload.index].name = payload?.name ?? '';
      })
      .addCase(reorderPlaylist.fulfilled, (state: PlaylistState, { payload }: PayloadAction<PlaylistEntryReorder>) => {
        state.list[payload.index].media = payload.medias;
        state.isLoading = false;
      })
      .addCase(newPlayList.fulfilled, (state: PlaylistState, { payload }: PayloadAction<Playlist>) => {
        state.list.push(payload);
      })
      .addCase(deletePlaylist.fulfilled, (state: PlaylistState, { payload }: PayloadAction<PlaylistRemove>) => {
        state.list.splice(payload.index, 1);
      })
      .addCase(choosePlaylist.fulfilled, (state: PlaylistState, { payload }: PayloadAction<PlaylistSelected>) => {
        state.current = payload.id;
        state.selectedPlaylist = payload.id;
        state.mediaIndex = 0;
      })
      .addCase(loadPlaylist.fulfilled, (state: PlaylistState, { payload }: PayloadAction<PageItems<Playlist>>) => {
        state.list = payload.items;
      })
      .addCase(exportPlaylist.fulfilled, (state: PlaylistState) => {
        state.isLoading = false;
      })
      .addCase(addToFavourite.fulfilled, (state: PlaylistState, { payload }: PayloadAction<FavouriteEntry>) => {
        state.favourites.media.push(payload.media);
      })
      .addCase(removeFromFavourites.fulfilled, (state: PlaylistState, { payload }: PayloadAction<FavouriteEntryRemove>) => {
        state.favourites.media.splice(payload.mediaIndex, 1);
      })
      .addCase(loadFavourites.fulfilled, (state: PlaylistState, { payload }: PayloadAction<Playlist>) => {
        state.favourites = payload;
      });
  },
});

export const selectPlaylist = (state: RootState): PlaylistState => {
  return state.playlistState;
}

export const selectSelectedPlaylist = (state: RootState): Playlist => {
  return selectPlaylist(state).list.find( playlist => playlist.id === state.playlistState.selectedPlaylist) ?? { id: -1 } as Playlist;
}

export const selectFavourites = (state: RootState): Playlist => {
  return selectPlaylist(state).favourites;
}

export default playlistSlice.reducer;