import React, { FormEvent } from 'react';

import SubmitButton, { Type } from '../submit_button';
import './styles.css';

type IProps = {
  onCancel: (event: FormEvent<HTMLInputElement>) => void;
}

function SaveCancelButtons({ onCancel }: IProps) {
  return (
    <div className="save_cancel_buttons_container">
      <SubmitButton className="save_cancel_buttons_button" type={Type.SAVE} />
      <SubmitButton className="save_cancel_buttons_button" type={Type.CANCEL} onClick={onCancel} />
    </div>
  );
}

export default SaveCancelButtons;