import React, { useEffect } from 'react';
import { Media, CharacterInfo } from 'model/types/api.types';
import { useSelector, useDispatch } from 'model/store';
import { UserPrefences } from 'model/types/user.types';
import { selectMedia, searchMediaMore, listMedia, listMediaMore } from 'model/reducers/mediaSlice';
import { addCharactersToList } from 'model/reducers/characterSlice';

import useToast from 'components/widgets/toast/useToast';
import Icon, { IconColor } from 'components/widgets/icon';
import ScrollLoader from 'components/widgets/scroll_loader';
import MediaItem from 'components/media/item';
import './styles.css';

type IProps = {
  preferences: UserPrefences,
}

function MediaList({ preferences }: IProps) {
  const dispatch = useDispatch();
  const { isLoading, isSearch, list } = useSelector(selectMedia);
  const { addErrorToast } = useToast();

  const loadMore = () => {
    if (isSearch) {
      dispatch(searchMediaMore({ page: list?.pageInfo?.page + 1 }));
    } else if (list?.pageInfo?.hasNext) {
      dispatch(listMediaMore({ page: list?.pageInfo?.page + 1 }));
    }
  }

  useEffect( () => {
    dispatch(listMedia()).then( ({ payload }: any) => {
      const items: Media[] = payload.items;
      const characters = items.reduce( (characterList, media) => {
        characterList.push(...media.characters);
        return characterList;
      }, [] as CharacterInfo[]);
      return dispatch(addCharactersToList({ characters })).catch(addErrorToast);
    });
  }, [dispatch, addErrorToast]);

  return (
    <>
      <div className="media_list_container">
        <ScrollLoader isLoading={isLoading} loadMore={loadMore} height={50} hasMore={list?.pageInfo?.hasNext}>
          {(list.items || []).map( (media) => 
            <MediaItem
              key={media.id}
              media={media}
              canEdit={preferences.editMode}
              showTitle={preferences.showTitle}
          />)}
        </ScrollLoader>
        { !isLoading && isSearch && list.items?.length === 0 && <div className="media_list_empty">
          <div className="media_list_empty_content">Your search yielded no results</div>
        </div>}
      </div>
      {list?.pageInfo?.hasNext && <div className="scroll_more_text" title="Scroll for more">
        <Icon name="arrow-down-circle" color={IconColor.LIGHT_GREY} alt="scroll for more" onClick={loadMore} />
      </div>}
    </>
  );
}

export default MediaList;