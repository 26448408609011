import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import {
  get_randomMedia,
} from '../apis/apis';
import { MAX_PREVIOUS_QUEUE_SIZE } from 'model/constants/sizes';
import { QueueState, QueueEntry, QueueEntryRemove, QueueFromPlaylist, QueueFromItems } from 'model/types/queue.types';
import { ReorderIndices } from 'model/types/playlist.types';
import { RootState } from 'model/store';

const initialState: QueueState = {
  currentMedia: null,
  autoPlay: true,
  list: [],
  previousMedia: [],
}

const getState = (thunkAPI: any) => {
  return (thunkAPI.getState() as RootState).queueState;
}

export const addRandomMedia = createAsyncThunk('/media/queue/random', async (_value, thunkAPI) => {
  const { previousMedia, currentMedia } = getState(thunkAPI);
  const mediaIds = previousMedia.map( item => item.id ) ?? [];
  if (currentMedia !== null) mediaIds.push(currentMedia?.id ?? 0);
  const { items } = await get_randomMedia(mediaIds);
  return { items };
});

const queueSlice = createSlice({
  name: 'queueState',
  initialState,
  reducers: {
    watchMedia(state, { payload }: PayloadAction<QueueEntry>) {
      state.currentMedia = payload.media;
    },
    clearMedia(state) {
      state.currentMedia = null;
      state.previousMedia = [];
    },
    nextMedia(state) {
      if (state.list.length > 0) {
        if (state.currentMedia !== null)
          state.previousMedia.push(state.currentMedia);
        state.currentMedia = state.list.shift() ?? null;
        if (state.previousMedia.length > MAX_PREVIOUS_QUEUE_SIZE) {
          state.previousMedia.splice(0, state.previousMedia.length - MAX_PREVIOUS_QUEUE_SIZE);
        }
      }
    },
    prevMedia(state) {
      if (state.previousMedia.length > 0) {
        if (state.currentMedia !== null) state.list.unshift(state.currentMedia);
        state.currentMedia = state.previousMedia.pop() ?? null;
      }
    },
    addToQueue(state, { payload }: PayloadAction<QueueEntry>) {
      state.list.push(payload.media);
    },
    addToNext(state, { payload }: PayloadAction<QueueEntry>) {
      state.list.unshift(payload.media);
    },
    removeFromQueue(state, { payload }: PayloadAction<QueueEntryRemove>) {
      state.list.splice(payload.mediaIndex, 1);
    },
    reorderQueue(state, { payload }: PayloadAction<ReorderIndices>) {
      const { newIndex, oldIndex } = payload;
      const medias = [...state.list];
      const item = medias.splice(oldIndex, 1)[0];
      medias.splice(newIndex, 0, item);
      state.list = medias;
    },
    choosePlaylistMedia(state, { payload }: PayloadAction<QueueFromPlaylist> ) {
      const { playlist, mediaIndex } = payload;
      state.currentMedia = playlist.media[mediaIndex];
      if (playlist.media.length > mediaIndex + 1)
        state.list = playlist.media.slice(mediaIndex + 1) ?? [];
      if (payload.mediaIndex > 0)
        state.previousMedia = playlist.media.slice(0, mediaIndex);
    },
    toggleAutoplay(state, { payload }: PayloadAction<boolean>) {
      state.autoPlay = payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(addRandomMedia.fulfilled, (state, { payload }: PayloadAction<QueueFromItems>) => {
        state.list = payload.items;
      });
  }
});

export const selectQueue = (state: RootState): QueueState => {
  return state.queueState;
}

export const selectQueueList = (state: RootState) => {
  return {
    autoPlay: selectQueue(state).autoPlay,
    hasNext: selectQueue(state).list.length > 0,
    hasPrev: selectQueue(state).previousMedia.length > 0,
  }
}

export const { actions } = queueSlice;

export default queueSlice.reducer;